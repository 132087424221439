import * as twtConfig from "./twt_config";  

export const LOGIN_COOKIE_NAME = twtConfig.LOGIN_COOKIE_NAME;
export const ERROR_COOKIE_NAME = twtConfig.ERROR_COOKIE_NAME;
export const BACKEND_FILE_URL = twtConfig.BACKEND_FILE_URL;
export const SITE_URL = twtConfig.SITE_URL;
export const API_BASE_URL = twtConfig.API_BASE_URL;
export const BASE_PATH = twtConfig.BASE_PATH;
export const LANGUAGE_FOLDER_URL = twtConfig.LANGUAGE_FOLDER_URL;
export const SITE_TITLE = twtConfig.SITE_TITLE;
export const ADMIN_PANEL_URL_PREFIX = twtConfig.ADMIN_PANEL_URL_PREFIX;
export const DEFAULT_TWT_ADMIN_EMAIL = twtConfig.DEFAULT_TWT_ADMIN_EMAIL;
export const DEFAULT_TWT_ADMIN_MOBILE = twtConfig.DEFAULT_TWT_ADMIN_MOBILE;
export const SHOW_FORGOT_PASSWORD_LINK = twtConfig.SHOW_FORGOT_PASSWORD_LINK;
export const SHOW_REMEMBER_ME_CHECKBOX = twtConfig.SHOW_REMEMBER_ME_CHECKBOX;
export const REMEMBER_ME_TIME = twtConfig.REMEMBER_ME_TIME;
export const RESEND_OTP_TIMER_KEY = twtConfig.RESEND_OTP_TIMER_KEY;
export const PRAHEAL_DOMAIN = twtConfig.PRAHEAL_DOMAIN;

//Pagination
export const PER_PAGE = twtConfig.PER_PAGE;
export const SELECT_ALL_ROWS_TEXT = twtConfig.SELECT_ALL_ROWS_TEXT;
export const PER_PAGE_OPTIONS = twtConfig.PER_PAGE_OPTIONS;
export const DEFAULT_PAGE_INDEX = twtConfig.DEFAULT_PAGE_INDEX;

//Show Confirm Box
export const SHOW_CONFIRM_BOX = twtConfig.SHOW_CONFIRM_BOX;

// Encryption Key 
export const ENCRYPTION_KEY = twtConfig.ENCRYPTION_KEY;

//Show Developer Settings
export const SHOW_DEVELOPER_SETTINGS = twtConfig.SHOW_DEVELOPER_SETTINGS;

// only Admin Panel 
export const ONLY_ADMIN_PANEL = twtConfig.ONLY_ADMIN_PANEL;

//send OTP
export const SEND_LOGIN_OTP = twtConfig.SEND_LOGIN_OTP;
export const OTP_LENGTH = twtConfig.OTP_LENGTH;
export const LOGIN_RESEND_OTP_TIME = twtConfig.LOGIN_RESEND_OTP_TIME;

export const DISPLAY_DATE_FORMAT = 'd-m-Y';
export const DISPLAY_DATE_TIME_FORMAT = 'd-m-Y h:i:s A';
export const DEFAULT_DATE_FORMAT = "DD-MM-YYYY";

export const ACTIVE_STATUS = 1;
export const INACTIVE_STATUS = 0;
export const SUCCESS_API_STATUS_CODE = 1;
export const ACTIVE_STATUS_TEXT = "Active";
export const INACTIVE_STATUS_TEXT = "Inactive";
export const ENABLE_STATUS_TEXT = "Enable";
export const DISABLE_STATUS_TEXT = "Disable";
export const SELECTION_YES = 'Yes';
export const SELECTION_NO = 'No';
export const LEFT = 'left';
export const RIGHT = 'right';
export const ROLE_ADMIN = 'admin';
export const ADMIN_THEME_COLOR = "#fff";
export const SINGLE_CHAT = "single";
export const GROUP_CHAT = "group";

export const ADMIN_LOGIN_MODULE = 'staff-login';
export const PATIENT_LOGIN_MODULE = 'patient-login';
export const PATIENT_REGISTRATION_MODULE = 'patient-registration';

export const LANDING_PAGE_URL = "/";
export const LOGIN_URL = '/login';
export const ADMIN_LOGIN_URL = '/' + ADMIN_LOGIN_MODULE;
export const PATIENT_LOGIN_URL = '/' + PATIENT_LOGIN_MODULE;
export const PATIENT_REGISTRATION_URL = '/' + PATIENT_REGISTRATION_MODULE;
export const FORGOT_PASSWORD_URL = '/forgot-password';
export const RESET_PASSWORD_URL = '/newPassword';
export const VERIFY_OTP_URL = '/verifyOtp';
export const LOGOUT_URL = '/logout';
export const DASHBOARD_PAGE_URL = ADMIN_PANEL_URL_PREFIX + '/dashboard';
export const SUCCESS_REDIRECT_PAGE_URL = ADMIN_PANEL_URL_PREFIX + '/appointments-calendar';
export const LOGIN_HISTORY_URL = ADMIN_PANEL_URL_PREFIX + '/login-history';
export const SETTING_URL = ADMIN_PANEL_URL_PREFIX + '/settings';
export const GET_SETTING_INFO_URL = '/getSettingInfo';
export const CHANGE_PASSWORD_PAGE_URL = ADMIN_PANEL_URL_PREFIX + '/change-password';
export const USER_URL = ADMIN_PANEL_URL_PREFIX + '/users';
export const LOOKUP_URL = ADMIN_PANEL_URL_PREFIX + '/lookup';
export const UPDATE_PASSWORD_URL = '/updatePassword';
export const CHECK_STRONG_PASSWORD_URL = '/checkStrongPassword';
export const CHECK_UNIQUE_USER_URL = '/checkUniqueUserEmail';
export const GET_CUSTOM_DROPDOWN_URL = '/getCustomDropdownDetails';
export const ADD_LOOKUP_URL = '/add-lookup-master';
export const SEND_FORGOT_PASSWORD_MAIL_URL = LOGIN_URL + '/sendForgotPasswordMail';
export const CHECK_LOGIN_URL = LOGIN_URL + '/checkLogin';
export const CHECK_OTP_URL = LOGIN_URL + '/checkOtp';
export const RESEND_OTP_URL = LOGIN_URL + '/resendLoginOtp';
export const EXCEPTION_PAGE_URL = ADMIN_PANEL_URL_PREFIX + '/exception';
export const THANK_YOU_PAGE_URL = '/thank-you';
export const PATIENT_ENQUIRY_URL = ADMIN_PANEL_URL_PREFIX + '/patient-enquiry';

export const DATATABLE_HEIGHT = 'calc(100vh - 260px)';

export const PROVISIONAL_DIAGNOSIS_MODULE = 'provisional-diagnosis';
export const PROVISIONAL_DIAGNOSIS_URL = ADMIN_PANEL_URL_PREFIX + '/' + PROVISIONAL_DIAGNOSIS_MODULE;
export const PROVISIONAL_DIAGNOSIS_LOOKUP = 'provisional_diagnosis';
export const CHIEF_COMPLAINTS_MODULE = 'chief-complaints';
export const CHIEF_COMPLAINTS_URL = ADMIN_PANEL_URL_PREFIX + '/' + CHIEF_COMPLAINTS_MODULE;
export const CHIEF_COMPLAINTS_LOOKUP = 'chief_complaint';
export const FLAGS_MODULE = 'flags';
export const FLAGS_URL = ADMIN_PANEL_URL_PREFIX + '/' + FLAGS_MODULE;
export const FLAGS_LOOKUP = 'flag';
export const ALLERGY_MODULE = 'allergy';
export const ALLERGY_URL = ADMIN_PANEL_URL_PREFIX + '/' + ALLERGY_MODULE;
export const ALLERGY_LOOKUP = 'allergy';
export const TYPE_OF_INVESTIGATIONS_MODULE = 'type-of-investigations';
export const TYPE_OF_INVESTIGATIONS_URL = ADMIN_PANEL_URL_PREFIX + '/' + TYPE_OF_INVESTIGATIONS_MODULE;
export const TYPE_OF_INVESTIGATIONS_LOOKUP = 'type_of_investigation';
export const PREFERRED_LANGUAGE_FOR_COMMUNICAION_LOOKUP = 'preferred_language_for_communicaion';
export const TYPE_OF_WORK_LOOKUP = 'type_of_work';

export const TEMPLATE_LOOKUP_URL = ADMIN_PANEL_URL_PREFIX + '/template-lookup';
export const ADD_TEMPLATE_LOOKUP_URL = '/add-template-lookup-master';
export const ADVICE_TEMPLATE_MODULE = 'advice-templates';
export const ADVICE_TEMPLATE_URL = ADMIN_PANEL_URL_PREFIX + '/' + ADVICE_TEMPLATE_MODULE;
export const ADVICE_TEMPLATE_TEMPLATE_LOOKUP = 'advice_template';
export const PRESCRIPTION_NOTE_TEMPLATE_MODULE = 'prescription-note-templates';
export const PRESCRIPTION_NOTE_TEMPLATE_URL = ADMIN_PANEL_URL_PREFIX + '/' + PRESCRIPTION_NOTE_TEMPLATE_MODULE;
export const PRESCRIPTION_NOTE_TEMPLATE_LOOKUP = 'prescription_note_template';
export const CLINICAL_NOTE_TEMPLATE_MODULE = 'clinical-note-templates';
export const CLINICAL_NOTE_TEMPLATE_URL = ADMIN_PANEL_URL_PREFIX + '/' + CLINICAL_NOTE_TEMPLATE_MODULE;
export const CLINICAL_NOTE_TEMPLATE_LOOKUP = 'clinical_note_template';
export const DISTRICT_URL = ADMIN_PANEL_URL_PREFIX + '/districts';
export const REFERRING_CONSULTANT_URL = ADMIN_PANEL_URL_PREFIX + '/referring-consultants';
export const PREFIX_DR = 'Dr.';
export const PREFIX_MR = 'Mr.';
export const PREFIX_MRS = 'Mrs.';
export const PREFIX_MS = 'Ms.';

export const INVESTIGATIONS_URL = ADMIN_PANEL_URL_PREFIX + "/investigations";
export const DRUGS_URL = ADMIN_PANEL_URL_PREFIX + "/drugs";

export const DRUG_TYPE_CAPSULE = 'Capsule';
export const DRUG_TYPE_CREAM = 'Cream';
export const DRUG_TYPE_DROPS = 'Drops';
export const DRUG_TYPE_FORM = 'Form';
export const DRUG_TYPE_GEL = 'Gel';
export const DRUG_TYPE_INHALER = 'Inhaler';
export const DRUG_TYPE_INJECTION = 'Injection';
export const DRUG_TYPE_LOTION = 'Lotion';
export const DRUG_TYPE_MOUTHWASH = 'Mouthwash';
export const DRUG_TYPE_OINTMENT = 'Ointment';
export const DRUG_TYPE_PATCH = 'Patch';
export const DRUG_TYPE_POWDER = 'Powder';
export const DRUG_TYPE_SHAMPOO = 'Shampoo';
export const DRUG_TYPE_SHOTS = 'Shots';
export const DRUG_TYPE_SPRAY = 'Spray';
export const DRUG_TYPE_SYRUP = 'Syrup';
export const DRUG_TYPE_TABLET = 'Tablet';
export const DRUG_TYPE_TOOTHPASTE = 'Toothpaste';
export const DRUG_TYPE_CUSTOM = 'Custom';
export const DRUG_TYPE_GARGLE = 'Gargle';
export const DRUG_TYPE_SUSPENSION = 'Suspension';
export const DRUG_TYPE_SERUM = 'Serum';
export const DRUG_TYPE_GLOBULES = 'Globules';
export const DRUG_TYPE_SOLUTION = 'Solution';

export const EXCEL_EXPORT_BUTTON_TYPE = 'excel';
export const SYSTEM_DEFAULT_ROW = 1;

export const ADMIN_FORGOT_PASSWORD_URL = '/admin-forgot-password';
export const PATIENT_FORGOT_PASSWORD_URL = '/patient-forgot-password';

export const ADMIN_FORGOT_PASSWORD_MODULE = 'admin-forgot-password';
export const PATIENT_FORGOT_PASSWORD_MODULE = 'patient-forgot-password';


export const INVESTIGATION_TEMPLATES_URL = ADMIN_PANEL_URL_PREFIX + "/investigations-advised-templates";
export const PRESCRIPTION_MEDICINE_TEMPLATES_URL = ADMIN_PANEL_URL_PREFIX + "/prescription-medicine-templates";

export const DURATION_PERIOD_DAY = 'day';
export const DURATION_PERIOD_WEEK = 'week';
export const DURATION_PERIOD_MONTH = 'month';
export const DURATION_PERIOD_YEAR = 'year';

export const SESSIONS_URL = ADMIN_PANEL_URL_PREFIX + "/sessions";
export const PROCEDURE_SESSION_MASTER_ID = 1;

export const SUB_SESSIONS_URL = ADMIN_PANEL_URL_PREFIX + "/sub-sessions";

export const SUBSCRIPTION_STATUS_TRIAL = 'Trial';
export const SUBSCRIPTION_STATUS_SUBSCRIBED = 'Subscribed';
export const SUBSCRIPTION_STATUS_RENEWAL_DUE = 'Renewal Due';
export const SUBSCRIPTION_STATUS_EXPIRED = 'Expired';

export const PAYMENT_RECIEVED_OPTIONS_YES = 'Yes';
export const PAYMENT_RECIEVED_OPTIONS_NO = 'No';
export const PAYMENT_RECIEVED_OPTIONS_PAYMENT_DUE = 'Payment Due';
export const PAYMENT_RECIEVED_OPTIONS_NOT_APPLICABLE = 'Not Applicable';

export const FIRMS_URL = ADMIN_PANEL_URL_PREFIX + "/firms";
export const PAGE_NOT_FOUND_URL = ADMIN_PANEL_URL_PREFIX + '/page-not-found';

export const RATE_CARDS_URL = ADMIN_PANEL_URL_PREFIX + '/rate-card';
export const RATE_CARDS_DELETE_DISABLED_ID = [1, 2];

export const CONDITIONS_URL = ADMIN_PANEL_URL_PREFIX + '/conditions';
export const EXERCISES_URL = ADMIN_PANEL_URL_PREFIX + '/exercises';

export const SYSTEM_LOGIN_ID = 1;
export const DEFAULT_COUNTRY_CODE = 'in';

export const PATIENTS_URL = ADMIN_PANEL_URL_PREFIX + '/patients';

export const GENDER_MALE = 'Male';
export const GENDER_FEMALE = 'Female';
export const GENDER_OTHER = 'Other';

//active since options
export const ACTIVE_SINCE_LESS_THAN_2_YEARS = 'Less Than 2 Years';
export const ACTIVE_SINCE_MORE_THAN_2_YEARS = 'More Than 2 Years';

//referred by options

export const REFERRED_BY_DOCTOR = 'Doctor';
export const REFERRED_BY_OLD_PATIENT = 'Old Patient';
export const REFERRED_BY_INTERNET = 'Internet';
export const REFERRED_BY_MEDIA = 'Media';
export const REFERRED_BY_CLINIC_ACTIVITY = 'Clinic Activity';
export const REFERRED_BY_ACQUANTANCE = 'Acquantance';

//internet options

export const INTERNET_FACEBOOK = 'Facebook';
export const INTERNET_GOOGLE = 'Google';
export const INTERNET_INSTAGRAM = 'Instagram';
export const INTERNET_YOUTUBE = 'Youtube';
export const INTERNET_QUORA = 'Quora';
export const INTERNET_OTHER = 'Other';

//media options

export const MEDIA_NEWSPAPER = "Newspaper";
export const MEDIA_TV = "TV";
export const MEDIA_CLINIC_BOARD = "Clinic Board";
export const MEDIA_HOARDING = "Hoarding";
export const MEDIA_OTHER = "Other";

//clinic Activity Options

export const CLINIC_ACTIVITY_SEMINAR = "Seminar";
export const CLINIC_ACTIVITY_WORKSHOP = "Workshop";
export const CLINIC_ACTIVITY_CAMP = "Camp";
export const CLINIC_ACTIVITY_OTHER = "Other";

//health Insurance Type Options

export const HEALTH_INSURANCE_TYPE_PERSONAL = "Personal";
export const HEALTH_INSURANCE_TYPE_CORPORATE = "Corporate";
export const HEALTH_INSURANCE_TYPE_GOVERNMENT = "Government";
export const HEALTH_INSURANCE_TYPE_OTHER = "Other";

//Date of Birth or Age options

export const DATE_OF_BIRTH = 'Date of Birth';
export const AGE = 'Age';

//relation With Patient Options

export const RELATION_WITH_SPOUSE = 'Spouse';
export const RELATION_WITH_DAUGHTER = 'Daughter';
export const RELATION_WITH_SON = 'Son';
export const RELATION_WITH_PARENT = 'Parent';
export const RELATION_WITH_FRIEND = 'Friend';
export const RELATION_WITH_RELATIVE = 'Relative';
export const RELATION_WITH_OTHER = 'Other';
export const LOGGED_FROM_ANOTHER_DEVICE = 'MultiLogging';

export const FORM_REQUEST_TYPE_ADD_PATIENT = 'Add Patient';
export const FORM_REQUEST_TYPE_PATIENT_REGISTRATION = 'Patient Registration';

export const PATIENT_STATUS_REGISTERED = 'Registered';
export const PATIENT_STATUS_CONSULTED = 'Consulted';
export const PATIENT_STATUS_ENQUIRY = 'Enquiry';

export const NOTIFICATION_FUNCTIONALITY_URL = ADMIN_PANEL_URL_PREFIX + "/notification-functionality";
export const FIRM_SETTINGS_URL = ADMIN_PANEL_URL_PREFIX + "/firm-settings";
export const FIRM_MASTER_ID = twtConfig.FIRM_MASTER_ID;

export const ACCESS_MANAGEMENT_URL = ADMIN_PANEL_URL_PREFIX + "/access-management";
export const INTERNAL_MESSAGING = ADMIN_PANEL_URL_PREFIX + "/internal-messaging";
export const ROLE_MEDICAL_DIRECTOR = 'Medical Director';
export const ROLE_RECEPTIONIST = 'Receptionist';
export const ROLE_CONSULTANT = 'Consultant';
export const ROLE_ACCOUNTANT = 'Accountant';
export const ROLE_STAFF = 'Staff';
export const ROLE_PATIENT = 'patient';

export const ACCESS_DENIED_URL = ADMIN_PANEL_URL_PREFIX + '/access-denied';
export const APPOINTMENTS_CALENDAR_URL = ADMIN_PANEL_URL_PREFIX + '/appointments-calendar';
export const APPOINTMENTS_LIST_URL = ADMIN_PANEL_URL_PREFIX + '/appointments-list';
export const APPOINTMENTS_URL = ADMIN_PANEL_URL_PREFIX + '/appointments';

// Type Of Appointment Options
export const TYPE_OF_APPOINTMENT_FIRST_CONSULTATION = "First Consultation";
export const TYPE_OF_APPOINTMENT_NEW_CONSULTATION = "New Consultation";
export const TYPE_OF_APPOINTMENT_FOLLOW_UP_CONSULTATION = "Follow-Up Consultation";
export const TYPE_OF_APPOINTMENT_PLAN = "Plan";
export const TYPE_OF_APPOINTMENT_DUMMY_APPOINTMENT = "Dummy Appointment";
export const APPOINTMENT_PENDING_STATUS = "Pending";
export const APPOINTMENT_WAITING_STATUS = "Waiting";
export const APPOINTMENT_ENGAGED_STATUS = "Engaged";
export const APPOINTMENT_ASSIGN_STATUS = "Assign";
export const APPOINTMENT_START_STATUS = "Start";
export const APPOINTMENT_DONE_STATUS = "Done";
export const APPOINTMENT_CANCEL_STATUS = "Cancel";
export const APPOINTMENT_NOSHOW_STATUS = "No Show";
export const SEND_MESSAGE_TYPE_EMAIL = 'email';
export const SEND_MESSAGE_TYPE_SMS = 'sms';

// Type Options
export const TYPE_NEW = "New";
export const TYPE_OLD = "Old";

export const MONDAY_PRACTICE_TIMING = 'Monday';
export const TUESDAY_PRACTICE_TIMING = 'Tuesday';
export const WEDNESDAY_PRACTICE_TIMING = 'Wednesday';
export const THURSDAY_PRACTICE_TIMING = 'Thursday';
export const FRIDAY_PRACTICE_TIMING = 'Friday';
export const SATURDAY_PRACTICE_TIMING = 'Saturday';
export const SUNDAY_PRACTICE_TIMING = 'Sunday';

export const FIRST_SESSION_PRACTICE_TIMING = 'Session 1';
export const SECOUND_SESSION_PRACTICE_TIMING = 'Session 2';
export const THIRD_SESSION_PRACTICE_TIMING = 'Session 3';

export const DEFAULT_COUNTRY = 'India';

// Add Consultation
export const ADD_CONSULTATION_URL = ADMIN_PANEL_URL_PREFIX + '/add-consultation';
export const CONSULTATION_URL_MODULE = 'consultation-master';
export const CONSULTATION_URL = ADMIN_PANEL_URL_PREFIX + '/' + CONSULTATION_URL_MODULE;
export const CONSULTATION_PLAN_URL = ADMIN_PANEL_URL_PREFIX + '/consultation-plan';

// Blood Group Options
export const BLOOD_GROUP_A_POSITIVE_TYPE = 'A Positive (A+)';
export const BLOOD_GROUP_A_NEGATIVE_TYPE = 'A Negative (A-)';
export const BLOOD_GROUP_B_POSITIVE_TYPE = 'B Positive (B+)';
export const BLOOD_GROUP_B_NEGATIVE_TYPE = 'B Negative (B-)';
export const BLOOD_GROUP_O_POSITIVE_TYPE = 'O Positive (O+)';
export const BLOOD_GROUP_O_NEGATIVE_TYPE = 'O Negative (O-)';
export const BLOOD_GROUP_AB_POSITIVE_TYPE = 'AB Positive (AB+)';
export const BLOOD_GROUP_AB_NEGATIVE_TYPE = 'AB Negative (AB-)';
export const BLOOD_GROUP_BOMBAY = 'Bombay Blood Group';

//Relationship Status Options
export const RELATIONSHIP_STATUS_MARRIED = 'Married';
export const RELATIONSHIP_STATUS_SINGLE = 'Single';
export const RELATIONSHIP_STATUS_DIVORCEE = 'Divorcee';
export const RELATIONSHIP_STATUS_SEPERATED = 'Seperated';
export const RELATIONSHIP_STATUS_COMPLICATED = 'Complicated';

// Consultation status options
export const CONSULTATION_STATUS_PENDING = 'Pending';
export const CONSULTATION_STATUS_ONGOING = 'Ongoing';
export const CONSULTATION_STATUS_DONE = 'Done';

// Investigation Type Options
export const INVESTIGATION_TYPE_ADVICE = 'Advice';
export const INVESTIGATION_TYPE_PAST = 'Past';

// Patient Dashboard
export const PATIENT_DASHBOARD_URL = '/patient-dashboard';
export const PATIENT_APPOINTMENTS_URL = '/patient-appointments';
export const PATIENT_PLANS_URL = '/patient-plans';
export const PATIENT_KRITIS_URL = '/patient-kritis';
export const PATIENT_PROFILE_URL = '/view-patient-profile';
export const FORM_REQUEST_TYPE_PATIENT_PROFILE = 'view-patient-profile';

//Tab Keys For Consultation Page
export const TAB_KEY_CONSULTATION = 'consultation';
export const TAB_KEY_CALENDAR = 'calendar';
export const TAB_KEY_PLAN = 'plan';
export const TAB_KEY_SESSION = 'session';
export const TAB_KEY_PROCEDURE = 'procedure';
export const TAB_KEY_SUB_SESSION_NOTE = 'sub-session-note';
export const TAB_KEY_KRITI = 'kriti';


//Type of Plan Status
export const PLAN_ACTIVE = 'Active';
export const PLAN_HOLD = 'Hold';
export const PLAN_STOP = 'Stop';
export const PLAN_REFUND = 'Refund';
export const PLAN_COMPLETE = 'Complete';
export const PLAN_PASSIVE = 'Passive';
export const PLAN_PRIVILEGE = 'Privilege';

//Discount type
export const DISCOUNT_TYPE_INR = 'INR';
export const DISCOUNT_TYPE_PERCENTAGE = 'Percentage';

// Rx Pdf Options
export const RX_PDF_OPTION_VITALS = 'Vitals';
export const RX_PDF_OPTION_CHIEF_COMPLAINTS = 'Chief Complaints';
export const RX_PDF_OPTION_SINCE_COMPLAINTS = 'Since';
export const RX_PDF_OPTION_REMARKS = 'Remarks';
export const RX_PDF_OPTION_PATIENT_HISTORY = 'Patient History';
export const RX_PDF_OPTION_CLINICAL_NOTES = 'Clinical Notes';
export const RX_PDF_OPTION_CLINICAL_DETAILS = 'Clinical Details';
export const RX_PDF_OPTION_PRESCRIPTION_NOTE = 'Prescription Note';
export const RX_PDF_OPTION_PROVISIONAL_DIAGNOSIS = 'Provisional Diagnosis';
export const RX_PDF_OPTION_PRESCRIPTION_DRUGS = 'Prescription Drugs';
export const RX_PDF_OPTION_PRESCRIPTION_DRUGS_WITH_GENERIC_NAME = 'Prescription Drugs With Generic Name';
export const RX_PDF_OPTION_INVESTIGATIONS = 'Investigations';
export const RX_PDF_OPTION_ADVICE = 'Advice';
export const RX_PDF_OPTION_NEXT_VISIT_AFTER = 'Next Visit After';
export const RX_PDF_OPTION_CONSULTANT_NAME = 'Consultant Name';
export const RX_PDF_OPTION_DESIGNATION = 'Designation';
export const RX_PDF_OPTION_CONSULTANT_ORTHOPEDIC_SURGEON = 'Consultant Orthopedic Surgeon';

// Pdf Option
export const RX_PDF_STATUS_PRINT = 'Print';
export const RX_PDF_STATUS_SHARE = 'Share';

// type of payment
export const TYPE_OF_PAYMENT_FIRST_CONSULTATION = 'First Consultation';
export const TYPE_OF_PAYMENT_NEW_CONSULTATION = 'New Consultation';
export const TYPE_OF_PAYMENT_FOLLOW_UP_CONSULTATION = 'Follow-Up Consultation';
export const TYPE_OF_PAYMENT_INVESTIGATION = 'Investigation';
export const TYPE_OF_PAYMENT_PROCEDURE = 'Procedure';
export const TYPE_OF_PAYMENT_PLAN = 'Plan';
export const TYPE_OF_PAYMENT_OTHER = 'Other';

export const INVOICE_URL = '/invoice';

// payment options
export const MODE_OF_PAYMENT_CASH = 'Cash';
export const MODE_OF_PAYMENT_CARD = 'Card';
export const MODE_OF_PAYMENT_UPI = 'UPI';
export const MODE_OF_PAYMENT_CHEQUE = 'Cheque';
export const MODE_OF_PAYMENT_NEFT_IMPS = 'NEFT/IMPS';

export const PDF_CONSULTATION = 'Consultation';
export const PDF_INVOICE = 'Invoice';

export const PAYMENT_STATUS_PAID = 'Paid';
export const PAYMENT_STATUS_DUE = 'Due';
export const PAYMENT_STATUS_UNPAID = 'Unpaid';
export const PAYMENT_STATUS_BALANCE = 'Balance';
export const PAYMENT_STATUS_PENDING = 'Pending';

export const PATIENT_DASHBOARD_FIRM_DETAILS_URL = PATIENT_DASHBOARD_URL + '/firm-details';

export const PRINT_OPTION_WITH_RECEIPT = "With Receipt";
export const ACCOUNTS_URL = ADMIN_PANEL_URL_PREFIX + "/accounts";
export const ACCOUNTS_URL_MODULE = "accounts";
export const PDF_TYPE_ACCOUNT = 'Account';
export const PDF_TYPE_RX = 'RX';
export const MAX_NO_SHOW_CANCEL_COUNT = 3;
export const PLAN_SUB_SESSION_STATUS_DONE = 'Done';
export const PLAN_SUB_SESSION_STATUS_DELETED = 'Deleted';
export const WELCOME_PAGE_URL = ADMIN_PANEL_URL_PREFIX + '/welcome-page';
export const INVOICE_MODULE = 'invoice';
export const RECEIPT_MODULE = 'receipt';

export const KRITI_URL = '/kriti';
export const KRITI_EXERCISE_URL = '/kriti/exercise';
export const KRITI_EXTEND_DURATION_URL = '/kriti/extend-duration';

export const EXERCISE_VIDEO_TYPE = 'Video';
export const EXERCISE_IMAGE_TYPE = 'Image';

export const PROCEDURE_TYPE_OPD = 'OPD';
export const PROCEDURE_TYPE_IPD = 'IPD';
export const PROCEDURE_TYPE_DAY_CARE = 'Day Care';
export const PLAN_ID_FOR_KRITI_KEY = 'plan_id_for_kriti';
export const PROCEDURE_URL = '/procedure';
export const FOLLOW_UPS_URL = '/follow-ups';
export const OPERATIONS_URL = ADMIN_PANEL_URL_PREFIX + '/operations';
export const OPERATION_FOR_INDIVIDUAL = 'Individual';
export const OPERATION_FOR_ALL = 'All';
export const URL_PREFIX = twtConfig.URL_PREFIX;
export const SOCKET_PORT = 6001;

// Board Component
export const BOARD_KEY_WHITEBOARD = "whiteboard";
export const BOARD_KEY_MY_NOTE = "my-note";
export const BOARD_KEY_REMIT_NOTE = "remit-note";
export const BOARD_URL = "/board";

export const BUFFERD_DAY_FOR_PLAN_TENTATIVE_END_DATE = 10;

export const TERMS_AND_CONDITIONS_URL = 'https://www.praheal.com/assets/images/condition.pdf';
export const PRIVACY_POLICIES_URL = 'https://www.praheal.com/assets/images/privacy.pdf';

export const REPORT_URL = ADMIN_PANEL_URL_PREFIX + '/reports';
export const DEFAULT_REPORT_TAB = 'consultation-report';
export const PDF_TYPE_RECEIPT = 'Receipt';