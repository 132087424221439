import React from "react";
import axios from "axios";
import * as Constants from "../../config/constants";
import alertify from 'alertifyjs';
import 'alertifyjs/build/css/alertify.css';
import CryptoJS from "crypto-js";
import { Link } from 'react-router-dom'
import {
	Button,
	Form
} from "react-bootstrap";
import loginVector1 from "../images/login-slider1.svg";
import loginVector2 from "../images/login-slider2.svg";
import loginVector3 from "../images/login-slider3.svg";
import loginVector4 from "../images/login-slider4.svg";
import { onPageChange, onSort } from "./prime-pagination";
var moment = require('moment');


// Designer 
export function handleActiveClass() {
    const currentPath = window.location.pathname;

    if (currentPath) {
        const dropdownNavLinks = document.querySelectorAll('.dropdown .nav-link, .dropdown .nav-items');
        const navLinks = document.querySelectorAll('.nav-items, li .nav-link');

        navLinks.forEach((navLink) => {
            const href = navLink.getAttribute('href');
            if (href === currentPath) {
                navLink.closest('.nav-items-class, .nav-item')?.classList.add('active');
            }
        });

        dropdownNavLinks.forEach((dropdownLink) => {
            const href = dropdownLink.getAttribute('href');
            if (href === currentPath) {
                dropdownLink.closest('.nav-items.dropdown')?.querySelector('.dropdown-toggle')?.classList.add('active');
                dropdownLink.parentElement.classList.add("active");
                dropdownLink.closest('.sub-dropdown-collapse')?.classList.add('show');
                dropdownLink.closest('.items-drodown-menu')?.classList.add('show');
                dropdownLink.closest('.dropdown-sub-megamenu')?.classList.add('active');
                dropdownLink.closest('.nav-items-class').classList.add('active');

                let parentMenu = dropdownLink.closest('.nav-items.dropdown');
                while (parentMenu) {
                    parentMenu.classList.add('active');
                    const collapseElement = parentMenu.querySelector('.collapse.navbar-collapse');
                    if (collapseElement) {
                        collapseElement.classList.add('show');
                    }
                    parentMenu = parentMenu.parentElement.closest('.nav-items.dropdown');
                }
            }
        });
    }
	
	document.querySelectorAll('.dropdown > .first-menu').forEach(a => {
        a.addEventListener('click', (e) => {
            if (window.innerWidth < 992) {
                e.preventDefault();
            }

            const dropdownMenus = document.querySelectorAll('.navbar-collapse');
            dropdownMenus.forEach(menu => {
                if (menu.classList.contains('show')) {
                    menu.classList.remove('show');
                }
            });
            e.stopPropagation();
        });
    });

		document.querySelectorAll('.sidebar .main-drodown-toggle').forEach((toggle) => {
			// Check if the click event listener has already been attached
			if (!toggle.hasEventListener) {
					toggle.addEventListener('click', (e) => {
							e.preventDefault();
							const parentLi = toggle.parentElement.closest('.nav-items.dropdown');
							parentLi.classList.toggle('active');
	
							// Remove "active" class from other menu items
							parentLi.parentElement.querySelectorAll('.nav-items.dropdown').forEach((sibling) => {
									if (sibling !== parentLi) {
											sibling.classList.remove('active');
									}
							});
	
							document.querySelectorAll('.nav-items-class.items-link').forEach((nonDropdownLink) => {
									nonDropdownLink.classList.remove('active');
							});
					});
	
					// Mark the element to indicate that the event listener has been attached
					toggle.hasEventListener = true;
			}
	});
	
}

export const toggleNav = () => {
  document.body.classList.toggle("nav-slide-open");
};

document.addEventListener("click", function(e) {
	if (window.innerWidth < 992 && !e.target.closest("#slide-toggle")) {
		document.body.classList.remove("nav-slide-open");
	}
});

window.addEventListener("scroll", function() {
	if (window.scrollY > 72) {
		document.querySelector(".twt-navbar")?.classList.add("fixed");
	} else {
		document.querySelector(".twt-navbar")?.classList.remove("fixed");
	}
});

export function showLoadingHandler(data) {
	setTimeout(()=>{
		if (data === true) {
			const parent = document.querySelector('.App');
			const newChild = `<div class="loader"><div class="loader--ring"><div></div><div></div><div></div><div></div></div></div>`;
			if(parent?.insertAdjacentHTML) parent.insertAdjacentHTML('beforeend', newChild);
		}else if(data === false){
			const loader = document.querySelector(".loader");
			if (loader) loader.parentNode.removeChild(loader);
		}
	}, 10)
}

export function showLoader(){
	showLoadingHandler(true);
}
export function hideLoader(){
	showLoadingHandler(false);
}

export function modalBackdrop(){
	setTimeout(() => {
		const modals = document.querySelectorAll('.modal');
		const backdrops = document.querySelectorAll('.modal-backdrop');
		const computedZIndex = 1050 + 10 * modals.length;
	
		backdrops.forEach((backdrop, index) => {
			if (index === backdrops.length - 1) {
				const zIndex = parseInt(computedZIndex);
				backdrop.style.zIndex = zIndex;
			}
		});

		modals.forEach((modal, index) => {
			if (index === backdrops.length - 1) {
				const zIndex = parseInt(computedZIndex + 1);
				modal.style.zIndex = zIndex;
			}
		});
	}, 50);
}

// End Designer

export function checkServerSideValidation(validationErrors , setError){
	
	if( typeof(validationErrors) !== "undefined"  ){
		if( Array.isArray(validationErrors)){
			validationErrors.forEach ( (value)  => {
				setError( '' + value.field +'', {
					message: value.message,
				});
			});
		}else{
			alertifyMessage("error" , validationErrors )
		}
	}
	
}

export function valueCheck(value) {
    return ((value && value !== null && value !== "") ? value : '');
}

export function validationError(error) {
    return(error && ((<span className="text-danger">{error?.message}</span>)));
}

// export const apiCall = async (method = 'get', url, data = null, isLoader = true, additionalProps = {}) => { 
// 	url = url.replace(Constants.ADMIN_PANEL_URL_PREFIX + '/' , "/");
//     let user = JSON.parse(localStorage.getItem(Constants.LOGIN_COOKIE_NAME));
//     let client = axios.create({
// 		baseURL: Constants.API_BASE_URL,
// 		headers: {
// 			'Access-Control-Allow-Origin': '*',
// 			'Authorization': ((user && user.token) ? ("Bearer " + user.token) : undefined),
// 			'Content-Type': 'multipart/form-data;',
// 		},
// 	});
  
//     var result = {};
// 	var response = {};
// 	if(isLoader !== false) {
// 		showLoader();
// 	}
// 	if( method === "get" ){
    	
//     	try {
//     		result =  client.get(url).then((responseData) => {
// 				if(responseData?.data?.status_code === 10010) {
// 					localStorage.setItem( Constants.LOGGED_FROM_ANOTHER_DEVICE , responseData?.data?.message);
// 					handleNoResponse();
// 				}
// 				if( responseData?.data?.status_code === 401 ){
// 					handleNoResponse();
// 				}
// 				if( responseData?.data?.status_code === 500 ) {
// 					handleException(responseData.data);
// 				}
// 				if( responseData?.data?.status_code === 403 ) {
// 					if (additionalProps?.navigate) additionalProps?.navigate(Constants.ACCESS_DENIED_URL);
// 				}
// 				if( responseData?.data?.status_code === 404 ) {
// 					if (additionalProps?.isPageRequest) {
// 						if (additionalProps?.navigate) additionalProps?.navigate(Constants.PAGE_NOT_FOUND_URL);
// 					} else {
// 						handlePageNotFound();
// 					}
// 				}
// 				if(responseData?.data?.status_code === 405) {
// 					handleNoResponse();
// 				}
// 				if( responseData?.data?.status_code === 101 && responseData?.data?.data?.consent_not_agreed) {
// 					handleConsentNotAgreed();
// 				}
// 				if(isLoader !== false) {
// 					hideLoader();
// 				}
//     			response.status = true;
//     			response.data = responseData.data;
// 	            return response;
// 	        }).catch((error) => {
// 	        	if(isLoader !== false) {
// 					hideLoader();
// 				}
// 	        	response.status = false;
// 	        	response.data = error;
// 	            return response;
// 	        });
//     	} catch (error) {
//     		if(isLoader !== false) {
// 				hideLoader();
// 			}
//     		response.status = false;
//         	response.data = error;
//         	return response;
//     	}
    	
//     	return result;
//     } else if( method === "post" ){
//     	try {
//     		result =  client.post(url,data).then((responseData) => {
// 				if(responseData?.data?.status_code === 10010) {
// 					localStorage.setItem( Constants.LOGGED_FROM_ANOTHER_DEVICE , responseData?.data?.message);
// 					handleNoResponse();
// 				}
// 				if( responseData?.data?.status_code === 401 ){
// 					handleNoResponse();
// 				}
// 				if( responseData?.data?.status_code === 500 ) {
// 					handleException(responseData.data);
// 				}
// 				if( responseData?.data?.status_code === 404 ) {
// 					handlePageNotFound();
// 				}
// 				if( responseData?.data?.status_code === 403 ) {
// 					if (additionalProps?.navigate) additionalProps?.navigate(Constants.ACCESS_DENIED_URL);
// 				}
// 				if(responseData?.data?.status_code === 405) {
// 					handleNoResponse();
// 				}
// 				if( responseData?.data?.status_code === 101 && responseData?.data?.data?.consent_not_agreed) {
// 					handleConsentNotAgreed();
// 				}
//     			if(isLoader !== false) {
// 					hideLoader();
// 				}
//     			response.status = true;
//     			response.data = responseData.data;
// 	            return response;
// 	        }).catch((error) => {
// 	        	if(isLoader !== false) {
// 					hideLoader();
// 				}
// 	        	response.status = false;
// 	        	response.data = error;
// 	            return response;
// 	        });
//     	}catch (error) {
//     		if(isLoader !== false) {
// 				hideLoader();
// 			}
//     		response.status = false;
//         	response.data = error;
//         	return response;
//     	}
//     	return result;
//     }
// }

export const apiCall = async (method, url, data = null, isLoader = true, additionalProps = {}) => { 
    url = url.replace(Constants.ADMIN_PANEL_URL_PREFIX + '/', "/");
    let user = JSON.parse(localStorage.getItem(Constants.LOGIN_COOKIE_NAME));
	if(!(checkCookie(`${Constants.LOGIN_COOKIE_NAME}_user_token`))) {
		document.cookie = `${Constants.LOGIN_COOKIE_NAME}_user_token=${user?.token}; path=/`;
	}
    let client = axios.create({
        baseURL: Constants.API_BASE_URL,
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Authorization': (user && user.token) ? ("Bearer " + user.token) : undefined,
            'Content-Type': 'multipart/form-data;',
        },
    });

    var response = {};
    if (isLoader !== false) {
        showLoader();
    }

    const config = {
        method,
        url,
        data,
        signal: additionalProps?.signal // Attach AbortController signal here
    };

    try {
        const result = await client(config);
        const statusCode = result?.data?.status_code;

        // Handle specific status codes
        if (statusCode === 10010) {
            localStorage.setItem(Constants.LOGGED_FROM_ANOTHER_DEVICE, result?.data?.message);
            additionalProps?.navigate(Constants.LOGOUT_URL);
        } else if (statusCode === 401) {
            handleNoResponse();
        } else if (statusCode === 500) {
            handleException(result.data);
        } else if (statusCode === 403 && additionalProps?.navigate) {
            additionalProps?.navigate(Constants.ACCESS_DENIED_URL);
        } else if (statusCode === 404) {
            if (additionalProps?.isPageRequest && additionalProps?.navigate) {
                additionalProps?.navigate(Constants.PAGE_NOT_FOUND_URL);
            } else {
                handlePageNotFound();
            }
        } else if (statusCode === 405) {
            handleNoResponse();
        } else if (statusCode === 101 && result?.data?.data?.consent_not_agreed) {
            handleConsentNotAgreed();
        }

        if (isLoader !== false) {
            hideLoader();
        }

        response.status = true;
        response.data = result.data;
    } catch (error) {
        if (isLoader !== false) {
            hideLoader();
        }

        if (axios.isCancel(error)) {
            response.status = false;
            response.data = { canceled: true };
        } else {
            response.status = false;
            response.data = error;
        }
    }

    return response;
};


alertify.defaults.transition = "slide";
alertify.defaults.theme.ok = "btn btn-primary btn-ok";
alertify.defaults.theme.cancel = "btn btn-danger btn-cancel";
alertify.defaults.theme.input = "form-control";
alertify.defaults.glossary.ok = "Yes"
alertify.defaults.glossary.cancel = "No"
export function alertifyMessage(type, message) {
	switch (type) {
		case "error":
			alertify.notify(message, "error", 5);
			break;
		case "success":
			alertify.notify(message, "success", 5);
			break;
		case "warning":
			alertify.notify(message, "warning", 5);
			break;
		case "info":
			alertify.notify(message);
			break;
		default:
			alertify.notify(message);
	}
}

export function manageNetworkError(response , t = null ) {
	
	var error_code = response?.data?.code ;
	if( error_code === "ERR_NETWORK"){
		alertifyMessage("error" , "Network Error. Please Contact System Administrator." );
	}
}

export function clientDateTime(dateTime) {
	return ((dateTime !== null && dateTime !== "") ? moment(dateTime).format('DD-MM-YYYY, hh:mm A') : '');
}

export function clientDate(date) {
	return ((date !== null && date !== "") ? moment(date, moment.ISO_8601).format("DD-MM-YYYY")  : '');
}

export function emailRegex(value, message = null) {
	if(value && value.trim()) {
		return (/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/).test(value.trim()) || message || "Please Enter Valid Email";
	}
}

export function mobileRegex(value , message = null) {
	if(value && value.trim()) {
		return (/^[6789]\d{9}$/).test(value.trim()) || message || "Please Enter Valid Mobile No.";
	}
}

export function baseName(path){
	return path.replace(/^.*(\\|\/|:)/, '');
}

export function onlyNumberWithSpaceAndPlusSign(e) {
	var val = e.target.value;
	e.target.value = val.replace(/[^ +\d]/g, "");
}

export function ucWord( message ) {
	var str = '';
	str = message.toLowerCase().replace(/\b[a-z]/g, function(letter) {
	    return letter.toUpperCase();
	});
	return str;
}

export function onlyDecimal(e, fieldValue = undefined) {
    let val = fieldValue ?? (e?.target ? e.target.value : "");

    // Remove leading zeros, but keep a single zero before the decimal point
    if (val.startsWith("0") && val.length > 1 && val[1] !== ".") {
        val = val.replace(/^0+/, "");
    } else if (val.startsWith(".")) {
		val = "0" + val;
	}

    // Remove invalid characters (anything that's not a digit or a dot)
    val = val.replace(/[^0-9.]/g, "");

    // Ensure only one decimal point exists
    const parts = val.split(".");
    if (parts.length > 2) {
        val = parts[0] + "." + parts.slice(1).join("").replace(/\./g, "");
    }

    // Limit to two decimal places
    if (parts[1] && parts[1].length > 2) {
        val = parts[0] + "." + parts[1].substring(0, 2);
    }

	if(fieldValue) return val;
    if(e?.target) e.target.value = val;
}

export function onlyNumber(e) {
	var val = e.target.value;
	e.target.value = val.replace(/[^\d]/g, "");
}

export function naturalNumber(e) {
	var val = e.target.value;
	e.target.value = val.replace(/[^\d]/g, "").replace(/^0+/g, "");
}

export function enumText(inputString){
	var str = inputString.replace(/_/g, ' ');
	str = str.toLowerCase().replace(/\b[a-z]/g, function(letter) {
	    return letter.toUpperCase();
	});
	return str;
}

export function onlyNumberWithSpace(e) {
	var val = e.target.value;
	e.target.value = val.replace(/[^ \d]/g, "");
}

export function imagePreview(file) {
	var objectUrl = null;

	if(file !== null && file !== "" && file !== undefined) {
		objectUrl = URL.createObjectURL(file)
	}
	return objectUrl;
}

export const designActionsDiv = (additionalCol = () => {}, formType = null , role = null , props = {}) => {
	return (
		<div className="actions-col-div">
			{ (formType === 'form' ?  <Link to={void(0)} className="btn btn-sm action-btn edit-btn" title="Edit"><i className="fa-fw fi fi-rr-pencil"></i></Link> :  <button type="button" title="Edit" className="btn btn-sm action-btn edit-btn btn-blur" ><i className="fi fi-rr-pencil"></i></button> ) }
			{( role !== Constants.ROLE_ADMIN ? <Button type="button" title="Delete" variant="" size="sm" className="action-btn delete-btn btn-blur" disabled={props?.deleteDisabled} ><i className="fi fi-rr-trash fa-fw"></i></Button> : '' )}
			{additionalCol()}
		</div>
	)
}

export const statusDiv = (e , updateRecordStatus , disabledBtn = Constants.SELECTION_NO) => {
 	var currentStatus = e.status;
 	var recordId = e.id
 	var setDisable = (disabledBtn === Constants.SELECTION_YES ? 'disabled' : '');
 	return (
		<Form.Check type="switch" className="twt-custom-switch justify-content-start" disabled={setDisable ? true : false} id={"customSwitches-"+recordId+""} checked={currentStatus === Constants.ENABLE_STATUS_TEXT ? true : false} current-status={currentStatus === Constants.ENABLE_STATUS_TEXT ? Constants.ENABLE_STATUS_TEXT : Constants.DISABLE_STATUS_TEXT} data-record-id={recordId} onChange={(e) => updateRecordStatus(e)} label={currentStatus === Constants.ENABLE_STATUS_TEXT ? 'Active' : 'Inactive' }/>
     )
}

const handleNoResponse = () => {
	localStorage.removeItem(Constants.LOGIN_COOKIE_NAME);
	window.dispatchEvent( new Event("storage"));
}

const handleConsentNotAgreed = () => {
	const localStorageData = JSON.parse(localStorage.getItem(Constants.LOGIN_COOKIE_NAME));
	localStorageData.is_agreed_to_consent = Constants.INACTIVE_STATUS;
	localStorage.setItem( Constants.LOGIN_COOKIE_NAME , JSON.stringify(localStorageData));
	window.dispatchEvent( new Event("storage"));
}

export function decode( string ){

	if(string === "" || string === null || string === "undefined" || string === undefined  ){
		return "";
	}
	
	var Utf8 = CryptoJS.enc.Utf8;
	const secretKey = Constants.ENCRYPTION_KEY
	const secretIv = Constants.ENCRYPTION_KEY + "_IV"
	const key= CryptoJS.SHA256(secretKey).toString(CryptoJS.enc.Hex).substring(0,32);
	let iv= CryptoJS.SHA256(secretIv).toString(CryptoJS.enc.Hex).substring(0,16);
	const encrypt = CryptoJS.enc.Base64.parse(string).toString(CryptoJS.enc.Utf8);
	const decrypt = CryptoJS.AES.decrypt(encrypt, Utf8.parse(key), { iv: Utf8.parse(iv)}).toString(Utf8);
	return decrypt;

}

export const updateSequence = async (filterData , recordDetails , initialSequence , currentPage , perPage , setRenderRequired) => {
	if(recordDetails?.length > 0) {
		const sequenceArray = [];
		recordDetails.map((data) => (
			sequenceArray.push(decode(data?.id))
		))
		if(sequenceArray.toString() === initialSequence.toString()) {
			alertifyMessage("error", "No Sequence Changed.");
		} else {
			filterData.new_sequence = sequenceArray.length > 0 ? sequenceArray.join(',') : '';
			filterData.last_page = currentPage;
			filterData.length = perPage;
			const response = await apiCall('post' , '/lookup/sequence-update' , filterData)
			if(response?.status !== false) {
				if(response?.data?.status_code === Constants.SUCCESS_API_STATUS_CODE) {
					setRenderRequired(true);
					alertifyMessage('success' , response?.data?.message);
				} else {
					alertifyMessage('error' , response?.data?.message);
				}
			} else {
				manageNetworkError(response);
			}
		}
	}
}

export const downloadBackup = async () => {
	const response = await apiCall('post' , '/dashboard/backup');
	if(response?.status !== false) {
		if(response?.data?.status_code === Constants.SUCCESS_API_STATUS_CODE) {
			downloadFile(response?.data?.data)
		} else {
			alertifyMessage("error", response?.data?.message);
		}
	} else {
		manageNetworkError(response);
	}
}

export const downloadFile = (attachment, customFileName = null) => {
    const linkSource = attachment?.data;
    const downloadLink = document.createElement('a');
	var fileName = null;
	if (customFileName !== null && customFileName !== "") {
		fileName = customFileName;
	} else {
		fileName = `${attachment?.file_name}.sql`;
	}
    
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
}

export const getStatusOptions = (t) => [
	{ value : Constants.ENABLE_STATUS_TEXT , label : t('active')},
	{ value : Constants.DISABLE_STATUS_TEXT , label : t('inactive')}
]

export const handleException = (exception) => {
	localStorage.setItem( Constants.ERROR_COOKIE_NAME , JSON.stringify(exception?.data));
	window.dispatchEvent( new Event("storage"));
}

export const handlePageNotFound = () => {
	alertifyMessage("error" , 'Page Not Found');
}

export const focusOut = () => {
	document.querySelectorAll(".btn-blur").forEach(e => {
		e.blur();
	})
}

export const alertifyConfirm = ( confirmTitle, confirmMessage, onOkAction = function() {} , onCancelAction = function() {}, ) => {
	alertify.confirm().set({
		onshow:function(){
			//disable delete button
			document.querySelectorAll(".delete-btn").forEach(e => {
				if (e.disabled) {
					if (e.hasAttribute("default-disabled") !== true) { 
						e.setAttribute("default-disabled", "true");
					}
				} else {
					e.disabled = true;
				}
			})
			
			//Remove tabindex of backdrop modal
			document.querySelectorAll('.modal').forEach(e => {
				e.removeAttribute('tabindex');
			});

			document.querySelector('.btn-ok').disabled = false;
			document.querySelector('.btn-cancel').disabled = false;
			
			// Focus on the OK button when the dialog is shown
			const okButton = document.querySelector('.btn-ok');
			if (okButton)
			okButton.focus();
		},
		onclose:function(){
			// Reassign tabindex of backdrop modal 
			document.querySelectorAll('.modal').forEach(e => {
				e.setAttribute('tabindex' , '-1');
			}); 
			
			//enable delete button
			document.querySelectorAll(".delete-btn").forEach(e => {
				if (e.hasAttribute("default-disabled") !== true) {
					e.disabled = false; // Enable the button
				}
			})

			document.querySelector('.btn-ok').disabled = true;
			document.querySelector('.btn-cancel').disabled = true;
		}
	});
	alertify.confirm(confirmTitle , confirmMessage , onOkAction , onCancelAction );
}

export const statusUpdate = async (thisItem, moduleUrl, setRenderRequired , statusInfo = {}) => {
	
	statusInfo.record_id = thisItem.getAttribute('data-record-id');
	statusInfo.current_status = thisItem.getAttribute('current-status');

	var updateStatus = await apiCall( 'post' , moduleUrl + '/updateStatus' , statusInfo );
	if( updateStatus?.status !== false ){
		if( updateStatus?.data?.status_code === Constants.SUCCESS_API_STATUS_CODE) {
				alertifyMessage("success" , updateStatus?.data?.message );
				setRenderRequired(true);
		   } else {
			   alertifyMessage("error" , updateStatus?.data?.message );
		   }	
	} else {
		manageNetworkError(updateStatus);
	}
}

//dynamic meta tags 
export const dynamicMetaTags = [
    'meta[name="title"]',
    'meta[name="description"]',
    'meta[name="keywords"]',
    'meta[name="author"]',
    'meta[name="copyright"]',
    'meta[name="publisher"]',

    'meta[property="og:title"]',
    'meta[property="og:description"]',
    'meta[property="og:url"]',
    'meta[property="og:site_name"]',
    'meta[property="og:image"]',

    'meta[property="twitter:url"]',
    'meta[property="twitter:title"]',
    'meta[property="twitter:description"]',
    'meta[property="twitter:image"]',

    'link[rel="icon"]',
    'link[rel="apple-touch-icon"]',
    
    'meta[name="theme-color"]'
];

export const noSpace = (value , fieldName) => {
	if(value && !value.toString().trim()) {
		return "Please Enter " + fieldName;
	}
}

export const inputTelPhoneValidate = (intelRef, value, message = "") => {
	if (!value) {
		return true;
	}
	let collectCountryData =  intelRef.current?.getInstance().getSelectedCountryData();
    value = value.replace('+' + collectCountryData?.dialCode, '');
	
	const errorMap = ["Invalid number", "Invalid country code", "Too short", "Too long", "Invalid number"];
	
	if (intelRef.current?.getInstance().isValidNumberPrecise()) {
		if(collectCountryData?.iso2 === Constants.DEFAULT_COUNTRY_CODE) {
			return (/^[6789]\d{9}$/).test(value) || message || "Please Enter Valid Mobile No.";
		}
		return true;
	} else {
		const errorCode = intelRef.current?.getInstance().getValidationError();
		const msg = message || errorMap[errorCode] || "Please Enter Valid Mobile No.";
		return msg;
	}
}

export const getTelCleanPhoneNumber = (intelRef, value = null) => {
	if(value !== '' && value !== null){
		let collectCountryData =  intelRef.current?.getInstance().getSelectedCountryData();
		return value.replace('+' + collectCountryData?.dialCode, '');	
	}
	return '';
}

export const handleSideBar = () => {
	// Close the sidebar on mobile devices for lookup pages.
	let sidebarWrapper = document.getElementById('wrapper');
	let contentArea = document.getElementById('backend-content');
	// For Horizontal Header Sidebar
	let horizontalHeader = document.getElementById('horizontal-header');

	if (window.innerWidth < 1199 && horizontalHeader !== null) {
		if ( window.innerWidth < 1199 && horizontalHeader?.classList.contains('nav-slide-open')) {
			horizontalHeader?.classList.remove('nav-slide-open');
		}
	} else {
		if(window.innerWidth < 1199){
			if (sidebarWrapper?.classList.contains('toggled') || contentArea?.classList.contains('toggled')) {
				sidebarWrapper?.classList.remove('toggled');
				contentArea?.classList.remove('toggled');
			}
		}
	}
	if(window.innerWidth > 1199){
		sidebarWrapper?.classList.add('toggled');
		contentArea?.classList.add('toggled');
		
	}

	if (window.innerWidth > 1199) {
		const wrapper = document.querySelector('#wrapper');
		const navSlideOpen = document.querySelector('#backend-content');
		if (wrapper && wrapper?.classList) {
			wrapper?.classList.add("toggled");
			navSlideOpen?.classList.add("nav-slide-open");
			navSlideOpen?.classList.add("toggled");
			
		}
	}
}

export const getPrefixOptions = (t) => [
	{ value: Constants.PREFIX_DR, label: t('dr') },
	{ value: Constants.PREFIX_MR, label: t('mr') },
	{ value: Constants.PREFIX_MRS, label: t('mrs') },
	{ value: Constants.PREFIX_MS, label: t('ms') }
];

export const getDrugType = (t) => [
	{ value: Constants.DRUG_TYPE_CAPSULE, label: t('capsule') },
	{ value: Constants.DRUG_TYPE_CREAM, label: t('cream') },
	{ value: Constants.DRUG_TYPE_CUSTOM, label: t('custom') },
	{ value: Constants.DRUG_TYPE_DROPS, label: t('drops') },
	{ value: Constants.DRUG_TYPE_FORM, label: t('form') },
	{ value: Constants.DRUG_TYPE_GARGLE, label: t('gargle') },
	{ value: Constants.DRUG_TYPE_GEL, label: t('gel') },
	{ value: Constants.DRUG_TYPE_GLOBULES, label: t('globules') },
	{ value: Constants.DRUG_TYPE_INHALER, label: t('inhaler') },
	{ value: Constants.DRUG_TYPE_INJECTION, label: t('injection') },
	{ value: Constants.DRUG_TYPE_LOTION, label: t('lotion') },
	{ value: Constants.DRUG_TYPE_MOUTHWASH, label: t('mouthwash') },
	{ value: Constants.DRUG_TYPE_OINTMENT , label: t('ointment') },
	{ value: Constants.DRUG_TYPE_PATCH, label: t('patch') },
	{ value: Constants.DRUG_TYPE_POWDER, label: t('powder') },
	{ value: Constants.DRUG_TYPE_SERUM, label: t('serum') },
	{ value: Constants.DRUG_TYPE_SHAMPOO, label: t('shampoo') },
	{ value: Constants.DRUG_TYPE_SHOTS, label: t('shots') },
	{ value: Constants.DRUG_TYPE_SOLUTION, label: t('solution') },
	{ value: Constants.DRUG_TYPE_SPRAY, label: t('spray') },
	{ value: Constants.DRUG_TYPE_SUSPENSION, label: t('suspension') },
	{ value: Constants.DRUG_TYPE_SYRUP, label: t('syrup') },
	{ value: Constants.DRUG_TYPE_TABLET, label: t('tablet') },
	{ value: Constants.DRUG_TYPE_TOOTHPASTE, label: t('toothpaste') }
].sort((value, afterValue) => value.value.localeCompare(afterValue.value));

export function onlyInteltelNumber(e, blockValidation) {
	var val = e.target.value;
	if(blockValidation !== false){
		//e.target.value = val.replace(/\+/g, "").replace(/[^0-9]/g, '');
		e.target.value = val.replace(/[^0-9+ ]/g, '');
	} else {
	e.target.value = val.replace(/\+/g, "").replace(/^0/, '').replace(/[^0-9]/g, '');
	}
}

export const durationOptions = (t) => [
	{ label : t('days'), value : Constants.DURATION_PERIOD_DAY },
	{ label : t('weeks'), value : Constants.DURATION_PERIOD_WEEK },
	{ label : t('months'), value : Constants.DURATION_PERIOD_MONTH },
	{ label : t('years'), value : Constants.DURATION_PERIOD_YEAR },
];

export const getDurationOptions = (type) => {
	var result = [];
	var row = {};
	if (type === 'hr') {
		for (let value of Array(24).keys()) {
			row = {};
			row.label = value.toString() + (value === 0 ? 0 : "") + ' Hrs';
			row.value = value;
			result.push(row);
		}
		return result;
	} else if (type === 'min') {
		for (let value of Array(60).keys()) {
			row = {};
			if (value % 5 === 0) {
				row.label = value.toString() + ' Mins';
				row.value = value;
				result.push(row);
			}
		}
		return result;
	}
}

export const printZeroValue = (value) => value === 0 ? "0" : value;

export const firmsSubscriptionStatusOptions = (t, formType = 'all') => {
	let result = [
		{ label : t('trial'), value : Constants.SUBSCRIPTION_STATUS_TRIAL }, 
		{ label : t('subscribed'), value : Constants.SUBSCRIPTION_STATUS_SUBSCRIBED }
	];
	
	if(formType !== 'add'){
		result.push(
			{ label : t('renewal-due'), value : Constants.SUBSCRIPTION_STATUS_RENEWAL_DUE },
			{ label : t('expired'), value : Constants.SUBSCRIPTION_STATUS_EXPIRED }
		)
	}

	return result;
};

export const firmsPaymentReceivedOptions = (t) => [
	{ label : t('yes'), value : Constants.PAYMENT_RECIEVED_OPTIONS_YES },
	{ label : t('no'), value : Constants.PAYMENT_RECIEVED_OPTIONS_NO },
	{ label : t('payment-due'), value : Constants.PAYMENT_RECIEVED_OPTIONS_PAYMENT_DUE },
	{ label : t('not-applicable'), value : Constants.PAYMENT_RECIEVED_OPTIONS_NOT_APPLICABLE },
];

export const uriSegment = (segment) => {
    const url = new URL(window.location.href); // Create URL object from full URL
    const path = url.pathname; // Get the pathname
    const segments = path.split('/').filter(Boolean); // Split and filter empty segments

    // Find the index of the last segment of SITE_URL in the pathname
    const baseSegments = Constants.SITE_URL.split('/').filter(Boolean); // Split SITE_URL into segments
    const baseIndex = segments.indexOf(baseSegments[baseSegments.length - 1]); // Get the index of the last segment

    // If no segment is provided, return all segments after SITE_URL
    if (segment === undefined) {
        return `/${segments.slice(baseIndex + 1).join('/')}`; // Return all segments after SITE_URL
    }

    // Calculate the index for reverse order
    const index = segments.length - segment; 

    // Check if the index is within bounds
    if (segment > 0 && index >= 0) {
        return segments[index]; // Return the segment based on the calculated index
    }

    return segments; // Return all segments if no valid index is provided
};

export const defaultSliderData = (t) => [
	{id: 1, vectorSrc: loginVector1, heading: <>Empower Your Health, <br /> One Click At A Time</>},
	{id: 2, vectorSrc: loginVector2, heading: <>Stay Connected, <br /> Stay Healthy</>},
	{id: 3, vectorSrc: loginVector3, heading: <>Your Health Journey, <br /> Seamlessly Managed</>},
	{id: 4, vectorSrc: loginVector4, heading: <>Smart Solutions For <br /> Smarter Health.</>},
];

export const getIntelNumberInfo = (intelNumber, ref) => {
	var numberInfo = ref.current?.getInstance().getSelectedCountryData();
	return {
		mobile: intelNumber.replace('+' + numberInfo?.dialCode, ''),
		country: numberInfo?.iso2,
		countryCode: '+' + numberInfo?.dialCode
	}
}

export const onlyAlphabetsWithoutSpace = (e, lowercase = false , setFieldValue = undefined) => {
	var fieldValue = e?.target?.value || '';
	fieldValue = fieldValue.toString();
	if(lowercase) {
		fieldValue = fieldValue.toLowerCase();
	}
	// eslint-disable-next-line
	e.target.value = fieldValue.replace(/[^a-zA-Z]/g, '');
	if(setFieldValue) {
		setFieldValue(e.target.value);
	}
}

export const checkUniqueColumnBaseOnModel = async (props = {}) => {
	const fieldName = props.fieldName;
	var fieldValue = props?.value || (props?.getValues ? props.getValues(fieldName) : "");
	if(!fieldValue) return true;
	
	fieldValue = fieldValue?.toString();
	if(!fieldValue?.trim()) return true;
	
	let data = {};
	data[fieldName] = fieldValue;
	if(props?.recordId) {
		data.record_id = props.recordId;
	}
	if(props?.additionalData) {
		data = {...data, ...props.additionalData};
	}
	const response = await apiCall('post' , props.url , data, (props?.showLoader || false));
	if(response?.status !== false) {
		if(response?.data?.status_code === Constants.SUCCESS_API_STATUS_CODE) {
			if(props?.clearErrors) {
				props.clearErrors(fieldName);
			}
			return true;
		} else {
			if(props.setError) {
				props.setError(fieldName , { message : props?.errorMessage || response?.data?.message });
			}
		}
	} else {
		manageNetworkError(response);
	}
	return false;
}

export const checkOptionIsDeleted = (dropdownId, dropdownOptions = null, key = 'id') => {
	if (dropdownOptions) {
		const checkOption = dropdownOptions.find(dropdownOption => dropdownOption[key] === dropdownId);
		if (checkOption) {
			return dropdownId ? dropdownId : "";
		}
		return "";
	} else {
		return dropdownId ? dropdownId : "";
	}
}

export const genderOptions = (t) => [
	{ label : t('male'), value : Constants.GENDER_MALE },
	{ label : t('female'), value : Constants.GENDER_FEMALE },
	{ label : t('other'), value : Constants.GENDER_OTHER },
]

export const yesNoDropDownOptions = (t) => [
	{ label : t('yes'), value : Constants.SELECTION_YES },
	{ label : t('no'), value : Constants.SELECTION_NO },
]

export function handleTableRowClick(rowSelector, activeClass = 'active-row', resetButtonSelector = '.reset-wild-tigers') {
    const tableRows = document.querySelectorAll(rowSelector);
	const resetButton = document.querySelector(resetButtonSelector);

    tableRows.forEach((row) => {
        row.addEventListener("click", function () {
            tableRows.forEach((r) => r.classList.remove(activeClass));
            this.classList.add(activeClass);
        });
    });

	if (resetButton) {
        resetButton.addEventListener("click", () => {
            tableRows.forEach((r) => r.classList.remove(activeClass));
        });
    }
}

export const activeSinceOptions = (t) => [
	{ label : t('less-than-2-years'), value : Constants.ACTIVE_SINCE_LESS_THAN_2_YEARS},
	{ label : t('more-than-2-years'), value : Constants.ACTIVE_SINCE_MORE_THAN_2_YEARS},
]

export const referredByOptions = (t) => [
	{ label : t('doctor'), value : Constants.REFERRED_BY_DOCTOR},
	{ label : t('old-patient'), value : Constants.REFERRED_BY_OLD_PATIENT},
	{ label : t('internet'), value : Constants.REFERRED_BY_INTERNET},
	{ label : t('media'), value : Constants.REFERRED_BY_MEDIA},
	{ label : t('clinic-activity'), value : Constants.REFERRED_BY_CLINIC_ACTIVITY},
	{ label : t('acquaintance'), value : Constants.REFERRED_BY_ACQUANTANCE},
]

export const internetOptions = (t) => [
	{ label : t('facebook'), value : Constants.INTERNET_FACEBOOK},
	{ label : t('google'), value : Constants.INTERNET_GOOGLE},
	{ label : t('instagram'), value : Constants.INTERNET_INSTAGRAM},
	{ label : t('youtube'), value : Constants.INTERNET_YOUTUBE},
	{ label : t('quora'), value : Constants.INTERNET_QUORA},
	{ label : t('other'), value : Constants.INTERNET_OTHER},
]

export const mediaOptions = (t) => [
	{ label : t('newspaper'), value : Constants.MEDIA_NEWSPAPER},
	{ label : t('tv'), value : Constants.MEDIA_TV},
	{ label : t('clinic-board'), value : Constants.MEDIA_CLINIC_BOARD},
	{ label : t('hoarding'), value : Constants.MEDIA_HOARDING},
	{ label : t('other'), value : Constants.MEDIA_OTHER},
]

export const clinicActivityOptions = (t) => [
	{ label : t('seminar'), value : Constants.CLINIC_ACTIVITY_SEMINAR},
	{ label : t('workshop'), value : Constants.CLINIC_ACTIVITY_WORKSHOP},
	{ label : t('camp'), value : Constants.CLINIC_ACTIVITY_CAMP},
	{ label : t('other'), value : Constants.CLINIC_ACTIVITY_OTHER},
]

export const healthInsuranceTypeOptions = (t) => [
	{ label : t('personal'), value : Constants.HEALTH_INSURANCE_TYPE_PERSONAL },
	{ label : t('corporate'), value : Constants.HEALTH_INSURANCE_TYPE_CORPORATE},
	{ label : t('government'), value : Constants.HEALTH_INSURANCE_TYPE_GOVERNMENT},
	{ label : t('other'), value : Constants.HEALTH_INSURANCE_TYPE_OTHER},
]

export const handleKeyPress = (e, setRenderRequired) => {
	if ((e.target.value !== null && e.target.value !== "") && (e.keyCode === 13 || e.which === 13)) {
		setRenderRequired(true);
	}
}

export const handleFilterField = (e, setFilterData, setRenderRequired = false, additionalFilterColumns = {}) => {
	setFilterData(filterData => {
		return { ...filterData, [e.target.name]: e.target.value,  ...additionalFilterColumns }
	});
	if (setRenderRequired) {
		setRenderRequired(true);
	}
}

export const handlePageChange = (e, filterData, setFilterData, setFirst, setRows, setRenderRequired) => {
	const updatedValues = onPageChange(e, filterData);
	setFilterData(updatedValues);
	setFirst(e?.first);
	setRows(e?.rows);
	setRenderRequired(true);
}

export const handleSort = (e, filterData, setFilterData, setRenderRequired) => {
	const updatedValues = onSort(e, filterData);
	setFilterData(updatedValues);
	setRenderRequired(true);
}

export const dateOfBirthOrAgeOptions = (t) => [
	{ label : t('date-of-birth'), value : Constants.DATE_OF_BIRTH },
	{ label : t('age'), value : Constants.AGE },
]

export const relationWithPatientOptions = (t) => [
	{ label : t('spouse'), value : Constants.RELATION_WITH_SPOUSE },
	{ label : t('daughter'), value : Constants.RELATION_WITH_DAUGHTER },
	{ label : t('son'), value : Constants.RELATION_WITH_SON },
	{ label : t('parent'), value : Constants.RELATION_WITH_PARENT },
	{ label : t('friend'), value : Constants.RELATION_WITH_FRIEND },
	{ label : t('relative'), value : Constants.RELATION_WITH_RELATIVE },
	{ label : t('other'), value : Constants.RELATION_WITH_OTHER },
]

export const patientStatusOptions = (t) => [
	{ label : t('enquiry'), value : Constants.PATIENT_STATUS_ENQUIRY },
	{ label : t('registered'), value : Constants.PATIENT_STATUS_REGISTERED },
	{ label : t('consulted'), value : Constants.PATIENT_STATUS_CONSULTED },
]

export const handleExportExcel = async (url, data) => {
	var payload = { ...data, export_button: Constants.EXCEL_EXPORT_BUTTON_TYPE };
	const response = await apiCall('post', url, payload);
	if(response?.status !== false) {
		if(response?.data?.status_code === Constants.SUCCESS_API_STATUS_CODE) {
			downloadFile(response?.data?.data, response?.data?.data?.file_name + '.xls');
		} else {
			alertifyMessage("error", response?.data?.message);
		}
	} else {
		manageNetworkError(response);
	}
}

export const getApplicableForBillingOption = (t) => [
	{ label: t('yes'), value: Constants.SELECTION_YES },
	{ label: t('no'), value: Constants.SELECTION_NO }
]

export const handleInputDrop = (event, field, onFieldChange) => {
	const isFromCKEditor = event.dataTransfer.getData('isFromCKEditor') === 'true';

	if (isFromCKEditor) {
		event.preventDefault();
		const data = event.dataTransfer.getData("text/plain");
		if (data) {
			field.onChange(data); // Update the input value with the dragged data
			if (onFieldChange) {
				onFieldChange(event);
			}
		}
	}
};

export const getStartOfDay = (date = new Date()) => {
	const startOfDay = new Date(date);
	startOfDay.setHours(0, 0, 0, 0); // sets hours, minutes, seconds, and milliseconds to 0
	return startOfDay;
}

export const getEndOfDay = (date = new Date()) => {
    const endOfDay = new Date(date);
    endOfDay.setHours(23, 59, 59, 999); // sets hours, minutes, seconds, and milliseconds to the end of the day
    return endOfDay;
}

export const replaceMultipleSpaces = (e) => {
	// Remove leading spaces only (not trailing ones)
	var val = e.target.value;
	val = val.replace(/^\s+/, '');
	
	// Replace multiple spaces between characters with a single space
	e.target.value = val.replace(/\s+/g, ' ');
}

export const cleanCKEditorContent = (content) => {
	if (content) {
		// Remove leading <br>, spaces, and &nbsp; from the start of the content
		content = content.replace(/^(<br\s*\/?>|&nbsp;|\s)+/, '');

		// This regex will remove all trailing <br>, spaces, and &nbsp; after the last non-whitespace, non-br, non-nbsp character
		content = content.replace(/(<br\s*\/?>|&nbsp;|\s)*<\/p>/g, '</p>');

		// Remove empty <p></p> tags
		content = content.replace(/<p>(\s|&nbsp;|<br\s*\/?>)*<\/p>/g, '');
	}
    return content;
}

// Function to check if all elements of arr1 are present in arr2
export const isArraySubset = (arr1, arr2) => {
	// Use the 'every' method to test if every element in arr1 is included in arr2
	return arr1.every(element => arr2.includes(element));
}

export const roleOptions = (t, adminUser = false) => {
	let options = [];
	options.push({ label : t('medical-director'), value : Constants.ROLE_MEDICAL_DIRECTOR });
	options.push({ label : t('consultant'), value : Constants.ROLE_CONSULTANT });
	if(adminUser !== true) {
		options.push({ label : t('receptionist'), value : Constants.ROLE_RECEPTIONIST });
		options.push({ label : t('accountant'), value : Constants.ROLE_ACCOUNTANT });
		options.push({ label : t('staff'), value : Constants.ROLE_STAFF });
	}

	return options;
}

export const relationWithUserOptions = relationWithPatientOptions;

// Helper function to parse time in "hh:mm AM/PM" format
const parseTime = (timeString) => {
	const [time, period] = timeString.split(' ');
	let [hours, minutes] = time.split(':').map(Number);
  
	// Handle "12 AM" as midnight and "12 PM" as noon
	if (period === 'AM' && hours === 12) {
	  hours = 0; // Midnight
	} else if (period === 'PM' && hours !== 12) {
	  hours += 12; // Convert PM hours to 24-hour format (e.g., 1 PM becomes 13)
	}
  
	return [hours, minutes];
  };
  
export const sessionOptions = (startTimeInput = null, endTimeInput = null) => {
	const sessionOptions = [];
	const startTime = new Date();
	startTime.setHours(0, 0, 0, 0); // Default start from 00:00
  
	const endOfDay = new Date();
	endOfDay.setHours(23, 59, 0, 0); // End at 23:59
  
	// Adjust start time if a specific start time is provided
	if (startTimeInput) {
	  const [startHour, startMinute] = parseTime(startTimeInput);
	  startTime.setHours(startHour, startMinute, 0, 0);
  
	  // Round up to the next 5-minute interval
	  const remainder = startTime.getMinutes() % 5;
	  if (remainder !== 0) {
		startTime.setMinutes(startTime.getMinutes() + (5 - remainder));
	  }
	}
  
	// Adjust end time if a specific end time is provided
	const endTime = endTimeInput ? new Date(startTime) : endOfDay;
	if (endTimeInput) {
	  const [endHour, endMinute] = parseTime(endTimeInput);
	  endTime.setHours(endHour, endMinute, 0, 0);
	}
  
	// Generate options based on the adjusted start and end times
	let currentTime = new Date(startTime);
  
	while (currentTime <= endOfDay) {
	  let hours = currentTime.getHours();
	  const minutes = currentTime.getMinutes().toString().padStart(2, '0');
	  const ampm = hours >= 12 ? 'PM' : 'AM';
  
	  // Convert 24-hour time to 12-hour time
	  hours = hours % 12;
	  hours = hours ? hours : 12; // 0 should be shown as 12
  
	  const label = `${hours}:${minutes} ${ampm}`;
  
	  // Include the option only if it meets the conditions:
	  // 1. It's after the specified start time (if provided).
	  // 2. It's outside the start-to-end range (if both provided).
	  // 3. It's before the end time (if only end time provided).
	  if (
		(!startTimeInput || currentTime > startTime) &&
		(!endTimeInput || currentTime < endTime)
	  ) {
		sessionOptions.push({ label: label, value: label });
	  }
  
	  // Increment by 5 minutes without rounding
	  currentTime = new Date(currentTime.getTime() + 5 * 60 * 1000); // Add exactly 5 minutes
	}
  
	return sessionOptions;
};

export const practiceTimingDays = (t) => [
	{ label : t('monday'), value : Constants.MONDAY_PRACTICE_TIMING },
	{ label : t('tuesday'), value : Constants.TUESDAY_PRACTICE_TIMING },
	{ label : t('wednesday'), value : Constants.WEDNESDAY_PRACTICE_TIMING },
	{ label : t('thursday'), value : Constants.THURSDAY_PRACTICE_TIMING },
	{ label : t('friday'), value : Constants.FRIDAY_PRACTICE_TIMING },
	{ label : t('saturday'), value : Constants.SATURDAY_PRACTICE_TIMING },
	{ label : t('sunday'), value : Constants.SUNDAY_PRACTICE_TIMING },
]

export const isPageAccessAllowed = (moduleName, permissions) => {
	// Get user data from local storage
    let user = JSON.parse(localStorage.getItem(Constants.LOGIN_COOKIE_NAME));	
    let userRole = (user && user?.role ? decode(user.role) : null);
    if (!user || !userRole) 
		return false;	// Deny access if no user or role is found

    else if (userRole === Constants.ROLE_ADMIN) 
		return true;	// Grant access if user is an admin

    else 
        return permissions?.some((permission) => permission === moduleName);	// Check permissions for regular users
};

export const appointmentTimeOptions = (startTime = null, endTime = null, timeDiff = 5) => {
	let options = [];
	if(!startTime) startTime = "00:00:00";
	if(!endTime) endTime = "24:00:00";
	startTime = moment(startTime, 'HH:mm:ss');
	endTime = moment(endTime, 'HH:mm:ss');
	while(!endTime.isSameOrBefore(startTime)) {
		let formattedTime = startTime.format('hh:mm A');
		options.push({ label : formattedTime, value : formattedTime });
		startTime.add(timeDiff, 'minutes');
	}
	return options;
}

export const typeOfAppointmentOptions = (t, type, all = false) => {
	let options = [];
	if (!type || all) {
		options.push({ label : t('first-consultation'), value : Constants.TYPE_OF_APPOINTMENT_FIRST_CONSULTATION });
	}
	if (type || all) {
		options.push({ label : t('plan'), value : Constants.TYPE_OF_APPOINTMENT_PLAN });
		options.push({ label : t('new-consultation'), value : Constants.TYPE_OF_APPOINTMENT_NEW_CONSULTATION });
		options.push({ label : t('follow-up-consultation'), value : Constants.TYPE_OF_APPOINTMENT_FOLLOW_UP_CONSULTATION });
	}
	options.push({ label : t('dummy-appointment'), value : Constants.TYPE_OF_APPOINTMENT_DUMMY_APPOINTMENT });

	return options;
}

export const getMinMaxTimeFromPracticeTimings = (users, diff = 0) => {
	const parseTime = (time) => moment(time, 'h:mm A');

	// Initialize min and max times
	let minTime = null;
    let maxTime = null;

    users.forEach(user => {
        user.practiceTimings.forEach(timing => {
            const startTime = timing?.start_time ? parseTime(timing.start_time) : null;
            const endTime = timing?.end_time ? parseTime(timing.end_time) : null;

            if (!minTime || (startTime && startTime.isBefore(minTime))) minTime = startTime;
            if (!maxTime || (endTime && endTime.isAfter(maxTime))) maxTime = endTime;
        });
    });

	// Adjust times
    let adjustedMinTime = minTime ? minTime.clone().subtract(diff, 'hour').format('HH:mm:ss') : null;
    let adjustedMaxTime = maxTime ? maxTime.clone().add(diff, 'hour').format('HH:mm:ss') : null;

	if(minTime.isBefore(moment('01:00:00', 'HH:mm:ss'))) adjustedMinTime = '00:00:00';
	if((maxTime.isAfter(moment('23:00:00', 'HH:mm:ss'))) || (adjustedMaxTime === '00:00:00')) adjustedMaxTime = '24:00:00';

    return {
        min: adjustedMinTime,
        max: adjustedMaxTime
    };
}

export const bloodGroupOptions = (t) => [
	{ label : t('a-positive'), value : Constants.BLOOD_GROUP_A_POSITIVE_TYPE },
	{ label : t('a-negative'), value : Constants.BLOOD_GROUP_A_NEGATIVE_TYPE },
	{ label : t('b-positive'), value : Constants.BLOOD_GROUP_B_POSITIVE_TYPE },
	{ label : t('b-negative'), value : Constants.BLOOD_GROUP_B_NEGATIVE_TYPE },
	{ label : t('o-positive'), value : Constants.BLOOD_GROUP_O_POSITIVE_TYPE },
	{ label : t('o-negative'), value : Constants.BLOOD_GROUP_O_NEGATIVE_TYPE },
	{ label : t('ab-positive'), value : Constants.BLOOD_GROUP_AB_POSITIVE_TYPE },
	{ label : t('ab-negative'), value : Constants.BLOOD_GROUP_AB_NEGATIVE_TYPE },
	{ label : t('bombay-blood-group'), value : Constants.BLOOD_GROUP_BOMBAY },
]

export const relationshipStatusOptions = (t) => [
	{ label : t('married'), value : Constants.RELATIONSHIP_STATUS_MARRIED },
	{ label : t('single'), value : Constants.RELATIONSHIP_STATUS_SINGLE },
	{ label : t('divorcee'), value : Constants.RELATIONSHIP_STATUS_DIVORCEE },
	{ label : t('separated'), value : Constants.RELATIONSHIP_STATUS_SEPERATED },
	{ label : t('complicated'), value : Constants.RELATIONSHIP_STATUS_COMPLICATED }
]

export const consultationStatusOptions = (t) => [
	{ label : t('ongoing'), value : Constants.CONSULTATION_STATUS_ONGOING },
	{ label : t('done'), value : Constants.CONSULTATION_STATUS_DONE }
]

export const investigationStatusOptions = (t) => [
	{ label : t('advice'), value : Constants.INVESTIGATION_TYPE_ADVICE },
	{ label : t('past'), value : Constants.INVESTIGATION_TYPE_PAST },
]

export const planOptions = (t) => [
	{ label : t('not-applicable'), value : Constants.SELECTION_NO },
	{ label : t('instruction-for-plan'), value : Constants.SELECTION_YES },
]

export const subDomainValidate = (value , fieldName) => {
	const notAllowedSubDomain = ["app", "www"];
	if(value && value.toString().trim() && notAllowedSubDomain.includes(value.toString().trim())) {
		return "This " + fieldName + " Is Not Allowed.";
	}
}

export const calculateBMI = (height, weight) => {
	let bmi = null;
	if (height && weight) {
	  const heightInMetres = height / 100;
	  const heightInMetreSquare = heightInMetres * heightInMetres;
	  bmi = weight / heightInMetreSquare;
	  bmi = Math.round(bmi * 100) / 100;  // rounding to 2 decimal places
	}
	return bmi;
}

export function calculateAge(dob) {
	// Parse the date in 'DD-MM-YYYY' format using Moment.js
	const birthDate = moment(dob, 'DD-MM-YYYY');
	
	// Get the current date and calculate the difference in years
	const age = moment().diff(birthDate, 'years');
  
	return age;
}

export function isBirthday(birthdate) {
	// Get today's date as a moment object
	const today = moment();
  
	// Parse the birthdate input into a moment object (day-month-year format)
	const birthday = moment(birthdate, "DD-MM-YYYY");
  
	// Set the birthday's year to the current year
	birthday.year(today.year());
  
	// Check if today matches the birthday (same day and month)
	return today.isSame(birthday, 'day');
}

export function getAgeFromDOB(birthdate, returnOnlyValue = false) {
    const today = moment();
    const birthDate = moment(birthdate, 'DD-MM-YYYY');
    const diff = today.diff(birthDate, 'days');
    
    if (diff === 0) {
        return returnOnlyValue ? 'Born Today' : <span>Born Today</span>;
    }

    const years = today.diff(birthDate, 'years');
    birthDate.add(years, 'years');
    const months = today.diff(birthDate, 'months');
    birthDate.add(months, 'months');
    const days = today.diff(birthDate, 'days');

    if (years === 0 && months === 0) {
        return returnOnlyValue ? `${days} Days` : <span>{days} Days</span>;
    } else if (years === 0 && months === 0 && days === 0) {
        return returnOnlyValue ? 'Born Today' : <span>Born Today</span>;
    } else if (years === 0 && months > 0 && days > 0) {
        const value = `${months} Months\n${days} Days`;
        return returnOnlyValue ? value : (
            <span>
                {months} Months <br />
                {days} Days
            </span>
        );
    } else if (years === 0 && months > 0) {
        return returnOnlyValue ? `${months} Months` : <span>{months} Months</span>;
    } else if (years === 1 && months === 0 && days === 0) {
        return returnOnlyValue ? '1 Yr.' : <span>1 Yr.</span>;
    } else if (years === 1 && (months > 0 || days > 0)) {
        const value = `1 Yr.${months > 0 ? `\n${months} Months` : ''}`;
        return returnOnlyValue ? value : (
            <span>
                1 Yr. {months > 0 ? <br /> : ''}
                {months > 0 && `${months} Months`}
            </span>
        );
    } else if (years > 1 && years < 18) {
        const value = `${years} Yrs.${months > 0 ? `\n${months} Months` : ''}`;
        return returnOnlyValue ? value : (
            <span>
                {years} Yrs. {months > 0 ? <br /> : ''}
                {months > 0 && `${months} Months`}
            </span>
        );
    } else if (years >= 18) {
        return returnOnlyValue ? `${years} Yrs.` : <span>{years} Yrs.</span>;
    }

    return returnOnlyValue ? 'Invalid Date' : <span>Invalid Date</span>;
}

export function formatDisplayNumber(number) {
    // Convert number to string
    const numStr = number.toString();
    
    // If number is less than or equal to 1000, return as is
    if (number <= 1000) {
        return `${numStr}`;
    }
    
    // Convert to string and split into groups
    const integerPart = numStr.replace(/\./g, '');
    
    // Function to add commas in Indian number format
    const formatIndianNumber = (str) => {
        // Separate the rightmost 3 digits
        const lastThree = str.slice(-3);
        const remaining = str.slice(0, -3);
        
        // If no remaining digits, just return last three
        if (remaining === '') {
            return lastThree;
        }
        
        // Add commas to remaining digits in groups of 2
        const formattedRemaining = remaining
            .split('')
            .reverse()
            .reduce((acc, digit, index) => {
                // Add comma after every 2 digits
                if (index > 0 && index % 2 === 0) {
                    return digit + ',' + acc;
                }
                return digit + acc;
            }, '')
            .replace(/^,/, '');
        
        // Combine remaining and last three
        return formattedRemaining + ',' + lastThree;
    };
    
    // Format the number and wrap in asterisks
    return `${formatIndianNumber(integerPart)}`;
}

export const getPlanStatusDetails = (t) => [
	{ label : t('active'), value : Constants.PLAN_ACTIVE },
	{ label : t('hold'), value :  Constants.PLAN_HOLD },
	{ label : t('stop'), value : Constants.PLAN_STOP },
	// { label : t('refund'), value : Constants.PLAN_REFUND },
	{ label : t('complete'), value : Constants.PLAN_COMPLETE },
	{ label : t('passive'), value : Constants.PLAN_PASSIVE },
	{ label : t('privilege'), value : Constants.PLAN_PRIVILEGE },
]

export const getCommonDateFilter = (t, excludeTentativeDate = false) => {
	const data = [
		{ label : t('start-date'), value : t('start-date') },
		{ label : t('end-date'), value : t('end-date') }
	];
	if (!excludeTentativeDate) {
		data.push({ label : t('tentative-start-date'), value : t('tentative-start-date') });
		data.push({ label : t('tentative-end-date'), value : t('tentative-end-date') });
	}
	return data;
}

export const getPaymentStatusOptions = (t, withPendingOption = false) => {
	const data = [
		{ label : t('paid'), value : Constants.PAYMENT_STATUS_PAID },
		{ label : t('due'), value :  Constants.PAYMENT_STATUS_DUE },
		{ label : t('unpaid'), value: Constants.PAYMENT_STATUS_UNPAID }
	];
	if (withPendingOption) {
		data.push({ label : t('pending'), value: Constants.PAYMENT_STATUS_PENDING });
	}
	return data;
}

export const validateLinkByUserAndRole = (appointment_status = null, permission = null) => {
	return (appointment_status && ![Constants.APPOINTMENT_CANCEL_STATUS, Constants.APPOINTMENT_NOSHOW_STATUS, Constants.APPOINTMENT_PENDING_STATUS, Constants.APPOINTMENT_WAITING_STATUS].includes(appointment_status) && isPageAccessAllowed("view_add_consultation", permission))
}

export const getRxPdfOptions = (t) => [
	{ label: t('vitals'), value: Constants.RX_PDF_OPTION_VITALS, checked: false },
	{ label: t('chief-complaints'), value: Constants.RX_PDF_OPTION_CHIEF_COMPLAINTS, checked: false },
	{ label: t('patient-history'), value: Constants.RX_PDF_OPTION_PATIENT_HISTORY, checked: false },
	{ label: t('clinical-notes'), value: Constants.RX_PDF_OPTION_CLINICAL_NOTES, checked: false },
	{ label: t('provisional-diagnosis'), value: Constants.RX_PDF_OPTION_PROVISIONAL_DIAGNOSIS, checked: true },
	{ label: t('clinical-details'), value: Constants.RX_PDF_OPTION_CLINICAL_DETAILS, checked: false },
	{ label: t('prescription-drugs'), value: Constants.RX_PDF_OPTION_PRESCRIPTION_DRUGS, checked: true },
	{ label: t('prescription-drugs-with-generic-name'), value: Constants.RX_PDF_OPTION_PRESCRIPTION_DRUGS_WITH_GENERIC_NAME, checked: false },
	{ label: t('prescription-note'), value: Constants.RX_PDF_OPTION_PRESCRIPTION_NOTE, checked: false },
	{ label: t('investigations'), value: Constants.RX_PDF_OPTION_INVESTIGATIONS, checked: true },
	{ label: t('advice'), value: Constants.RX_PDF_OPTION_ADVICE, checked: true },
	{ label: t('next-visit-after'), value: Constants.RX_PDF_OPTION_NEXT_VISIT_AFTER, checked: true },
	{ label: t('consultant-name'), value: Constants.RX_PDF_OPTION_CONSULTANT_NAME, checked: true },
	{ label: t('designation'), value: Constants.RX_PDF_OPTION_DESIGNATION, checked: true }
]

export const getPdfOptions = (t) => [
	{ label : t('share'), value : Constants.RX_PDF_STATUS_SHARE },
	{ label : t('print'), value : Constants.RX_PDF_STATUS_PRINT },
]

export const checkAppoinmentWithin24Hours = (appointmentStart = null) => {
	if(!appointmentStart) return true;

	const appointmentDate = moment(appointmentStart);
	const currentDate = moment();

	const hoursDifference = currentDate.diff(appointmentDate, "hours");

	return (hoursDifference >= 0 && hoursDifference <= 24);
};

export const typeOfPayment = (t) => [
	{ label : t('first-consultation'), value : Constants.TYPE_OF_PAYMENT_FIRST_CONSULTATION },
	{ label : t('new-consultation'), value : Constants.TYPE_OF_PAYMENT_NEW_CONSULTATION },
	{ label : t('follow-up-consultation'), value : Constants.TYPE_OF_PAYMENT_FOLLOW_UP_CONSULTATION },
	{ label : t('investigation'), value : Constants.TYPE_OF_PAYMENT_INVESTIGATION },
	// { label : t('procedure'), value : Constants.TYPE_OF_PAYMENT_PROCEDURE },
	{ label : t('plan'), value : Constants.TYPE_OF_PAYMENT_PLAN },
	{ label : t('other'), value : Constants.TYPE_OF_PAYMENT_OTHER },
]

export const discountOptions = (t) => [
	{ label : t('indian-currency'), value : Constants.DISCOUNT_TYPE_INR },
	{ label : '%', value : Constants.DISCOUNT_TYPE_PERCENTAGE },
]

export const paymentOptions = (t) => [
	{ label : t('cash'), value : Constants.MODE_OF_PAYMENT_CASH },
	{ label : t('card'), value : Constants.MODE_OF_PAYMENT_CARD },
	{ label : t('upi'), value : Constants.MODE_OF_PAYMENT_UPI },
	{ label : t('cheque'), value : Constants.MODE_OF_PAYMENT_CHEQUE },
	{ label : t('neft-imps'), value : Constants.MODE_OF_PAYMENT_NEFT_IMPS },
]

export const getformattedNumber = (amount) => {
	// Round to 2 decimal places & Format with commas as thousand separators
	let formattedAmount = new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(amount)
	return formattedAmount;
}

export const validateDiscount = (t, permissions, discount, discountType, amount = 0) => {
	let discountInPercentage = 0;
	if(discount) {
		if(discountType === Constants.DISCOUNT_TYPE_PERCENTAGE) discountInPercentage = parseFloat(discount);
		else if(discountType === Constants.DISCOUNT_TYPE_INR && parseFloat(amount) > 0) discountInPercentage = (parseFloat(discount) / parseFloat(amount)) * 100;
	}
	if(discountInPercentage > 100 || (parseFloat(amount) <= 0 && parseFloat(discount) > 0)) return t('discount-validation-message');
	if(!isPageAccessAllowed('view_discount', permissions) && discountInPercentage > 10) return t('discount-permission');
	return true;	
} 

export const validateNegativeAmount = (amount) => {
	return amount > 0 ? amount : 0;
}

export const getAccountPdfOptions = (t) => [
	{ label : t('with-receipt'), value : Constants.PRINT_OPTION_WITH_RECEIPT, checked: true },
]

export const getPatientDue = async (patientId = null) => {
	const response = await apiCall('get', 'patient-due' + (patientId ? '/'+ patientId: ''), null, true);
    	if (response?.status === true) {
        	if (response?.data?.status_code === Constants.SUCCESS_API_STATUS_CODE) {
          	return response?.data?.data;
        } else {
          alertifyMessage('error', response?.data?.message);
        }
    } else {
        manageNetworkError(response);
    }
}

export const invoiceStatusOptions = (t) => [
	{ label : t('active'), value : Constants.ENABLE_STATUS_TEXT },
	{ label : t('canceled'), value : Constants.DISABLE_STATUS_TEXT },
]

export const extractTimeFromString = (input) => {
	const regex = /\(in (\d+)\s*(\w+)\)/;
	const match = input.match(regex);
  
	if (match) {
	  const value = parseInt(match[1], 10); // Extract the number
	  const unit = match[2]; // Extract the unit and convert to lowercase
	  return { value, unit };
	}
  
	return null; // Return null if the format doesn't match
}

export const checkNotEmptyString = (string) => {
	return (string !== undefined && string !== "" && string !== null);
}

export const checkPatientRole = (user = null, defaultValue = null) => {
	const userRole = (user && user?.role ? decode(user?.role): null);
	if (userRole && userRole === Constants.ROLE_PATIENT) return false;
	else return defaultValue;
}

export const getLetterheadA4PaperPdfOptions = (t) => [
	{ label : t('on-letterhead-a4-paper'), value : Constants.ACTIVE_STATUS },
]

export const validateSize = (testVar, validateVar) => {
	if (testVar && validateVar) {
		const unitsToMb = {
			'B': 1 / (1024 * 1024),
			'KB': 1 / 1024,
			'MB': 1,
			'GB': 1024,
			'TB': 1024 * 1024
		};
	
		const [amount, type] = validateVar.split(" ");
		const [testAmount, testType] = testVar.split(" ");
	
		const validateSizeInMb = parseFloat(amount) * (unitsToMb[type.toUpperCase()] || 1);
		const testSizeInMb = parseFloat(testAmount) * (unitsToMb[testType.toUpperCase()] || 1);

		return testSizeInMb > validateSizeInMb;
	}
	return false;
}

export const checkCookie = (cookieName) => {
	const cookies = document.cookie.split(';');
	for (let i = 0; i < cookies.length; i++) {
	  let cookie = cookies[i].trim();
	  if (cookie.startsWith(cookieName + '=')) {
		return true; // Cookie exists
	  }
	}
	return false; // Cookie does not exist
  };