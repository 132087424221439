import {React, lazy, Suspense} from 'react';
import * as Constants from "../config/constants";
import { decode, isPageAccessAllowed } from '../assets/js/common';

const Login = lazy(() => import('../pages/admin/login'));
const ForgotPassword = lazy(() => import('../pages/admin/forgot-password'));
const ResetPassword = lazy(() => import('../pages/admin/reset-password'));
const VerifyOtp = lazy(() => import('../pages/admin/verify-otp'));
const Logout = lazy(() => import('../pages/admin/logout'));
const Exception = lazy(() => import('../pages/errors/exception-page'));
const Dashboard = lazy(() => import('../pages/admin/dashboard/dashboard'));
const LoginHistory = lazy(() => import('../pages/admin/login-history/login-history'));
const ChangePassword = lazy(() => import('../pages/admin/dashboard/change-password'));
const Settings = lazy(() => import('../pages/admin/settings/settings'));
const User = lazy(() => import('../pages/admin/users/users'));
const AddUser = lazy(() => import('../pages/admin/users/add-users'));
const Lookup = lazy(() => import('../pages/admin/lookup/lookup'));
const TemplateLookup = lazy(() => import('../pages/admin/template-lookup/template-lookup'));
const Districts = lazy(() => import('../pages/admin/districts/districts'));
const ReferringConsultants = lazy(() => import('../pages/admin/referring-consultants/referring-consultants'));
const Investigations = lazy(() => import('../pages/admin/investigations/investigations'));
const Drugs = lazy(() => import('../pages/admin/drugs/drugs'));
const InvestigationTemplates = lazy(() => import('../pages/admin/investigation-templates/investigation-templates'));
const PrescriptionMedicineTemplates = lazy(() => import('../pages/admin/prescription-medicine-templates/prescription-medicine-templates'));
const Sessions = lazy(() => import('../pages/admin/sessions/sessions'));
const SubSessions = lazy(() => import('../pages/admin/sub-sessions/sub-sessions'));
const AddSubSession = lazy(() => import('../pages/admin/sub-sessions/add-sub-session'));
const Firms = lazy(() => import('../pages/admin/firms/firms'));
const AddFirms = lazy(() => import('../pages/admin/firms/add-firm'));
const AdminError404 = lazy(() => import('../pages/errors/admin/404'));
const RateCards = lazy(() => import('../pages/admin/rate-card/rate-card'));
const Conditions = lazy(() => import('../pages/admin/conditions/conditions'));
const Exercises = lazy(() => import('../pages/admin/exercises/exercises'));
const AddExercise = lazy(() => import('../pages/admin/exercises/add-exercise'));
const Patients = lazy(() => import('../pages/admin/patients/patients'));
const AddPatient = lazy(() => import('../pages/admin/patients/add-patient'));
const PatientRegistration = lazy(() => import('../pages/admin/patients/patient-registration'));
const ThankYou = lazy(() => import('../pages/admin/thank-you/thank-you'));
const PatientEnquiry = lazy(() => import('../pages/admin/patients/patient-enquiry'));
const NotificationFunctionality = lazy(() => import('../pages/admin/notification-functionality/notification-functionality'));
const FirmSettings = lazy(() => import('../pages/admin/firm-settings/firm-settings'));
const AccessManagement = lazy(() => import('../pages/admin/access-management/access-management'));
const AddAccessManagement = lazy(() => import('../pages/admin/access-management/add-access-management'));
const InternalMessaging = lazy(() => import('../pages/admin/internal-messaging/internal-messaging'));
const AccessDenied = lazy(() => import('../pages/admin/access-denied'));
const AppointmentsCalendar = lazy(() => import('../pages/admin/appointments/appointments-calendar'));
const AppointmentList = lazy(() => import('../pages/admin/appointments/appointments'));
const AddConsultation = lazy(() => import('../pages/admin/consultation/add-consultation'));
const PatientDashboard = lazy(() => import('../pages/admin/patients/patient-dashboard'));
const PatientAppointments = lazy(() => import('../pages/admin/patients/patient-appointments'));
const PatientPlans = lazy(() => import('../pages/admin/patients/patient-plans'));
const PatientKritis = lazy(() => import('../pages/admin/patients/patient-kritis'));
const PatientProfile = lazy(() => import('../pages/admin/patients/view-patient-profile'));
const Accounts = lazy(() => import('../pages/admin/accounts/accounts'));
const Welcome = lazy(() => import('../pages/admin/welcome'));
const Operations = lazy(() => import('../pages/admin/operations/operations'));
const Reports = lazy(() => import('../pages/admin/reports/reports'));

export const backendRoutes = (t, permissions) => {
    const user = JSON.parse(localStorage.getItem(Constants.LOGIN_COOKIE_NAME));
    return [
        {
            path: Constants.LANDING_PAGE_URL,
            component: <Suspense><Dashboard pageName={t('dashboard')} permissions={permissions}/></Suspense>,
            pageAccess: true,
        },
        {
            path: Constants.ADMIN_LOGIN_URL,
            component: <Suspense><Login pageName={t('login')} /></Suspense>,
            pageAccess: true,
        },
        {
            path: Constants.LOGOUT_URL,
            component: <Suspense><Logout /></Suspense>,
            pageAccess: true,
            isPatientRoute: true
        },
        {
            path: Constants.DASHBOARD_PAGE_URL,
            component: <Suspense><Dashboard pageName={t("dashboard")} permissions={permissions}/></Suspense>,
            pageAccess: true,
        },
        {
            path: Constants.LOGIN_HISTORY_URL,
            component: <Suspense><LoginHistory pageName={t("login-history")} permissions={permissions}/></Suspense>,
            pageAccess: true,
        },
        {
            path: Constants.CHANGE_PASSWORD_PAGE_URL,
            component: <Suspense><ChangePassword pageName={t("change-password")} permissions={permissions}/></Suspense>,
            pageAccess: true,
        },
        {
            path: Constants.SETTING_URL,
            component: <Suspense><Settings pageName={t("settings")} permissions={permissions}/></Suspense>,
            pageAccess: (user && user?.firm_id !== undefined && user?.firm_id === null ? true: false),
        },
        {
            path: Constants.USER_URL,
            component: <Suspense><User pageName={t("users")} permissions={permissions} /></Suspense>,
            pageAccess: isPageAccessAllowed('view_user', permissions),
        },
        {
            path: `${Constants.USER_URL}/create`,
            component: <Suspense><AddUser pageName={t("add-user")} permissions={permissions}/></Suspense>,
            pageAccess: isPageAccessAllowed('add_user', permissions),
        },
        {
            path: `${Constants.USER_URL}/edit/:recordId`,
            component: <Suspense><AddUser pageName={t("update-user")} permissions={permissions}/></Suspense>,
            pageAccess: isPageAccessAllowed('edit_user', permissions),
        },
        {
            path: Constants.ADMIN_FORGOT_PASSWORD_URL,
            component: <Suspense><ForgotPassword pageName={t("forgot-password")} /></Suspense>,
            pageAccess: true,
        },
        {
            path: `${Constants.RESET_PASSWORD_URL}/:encodedData`,
            component: <Suspense><ResetPassword pageName={t("reset-password")} /></Suspense>,
            pageAccess: true,
        },
        {
            path: `${Constants.VERIFY_OTP_URL}/:userId/:isRememberMeChecked`,
            component: <Suspense><VerifyOtp pageName={t("verify-otp")} /></Suspense>,
            pageAccess: true,
        },
        {
            path: Constants.EXCEPTION_PAGE_URL,
            component: <Suspense><Exception pageName={t("internal-server-error")} /></Suspense>,
            pageAccess: true,
        },
        {
            path: Constants.PROVISIONAL_DIAGNOSIS_URL,
            component: <Suspense><Lookup permissions={permissions} /></Suspense>,
            pageAccess: isPageAccessAllowed('view_provisional_diagnosis', permissions),
        },
        {
            path: Constants.ADVICE_TEMPLATE_URL,
            component: <Suspense><TemplateLookup permissions={permissions} /></Suspense>,
            pageAccess: isPageAccessAllowed('view_advice_template', permissions),
        },
        {
            path: Constants.PATIENT_LOGIN_URL,
            component: <Suspense><Login pageName={t("patient-login")} /></Suspense>,
            pageAccess: true,
        },
        {
            path: Constants.CHIEF_COMPLAINTS_URL,
            component: <Suspense><Lookup permissions={permissions} /></Suspense>,
            pageAccess: isPageAccessAllowed('view_chief_complaint', permissions),
        },
        {
            path: Constants.PRESCRIPTION_NOTE_TEMPLATE_URL,
            component: <Suspense><TemplateLookup permissions={permissions} /></Suspense>,
            pageAccess: isPageAccessAllowed('view_prescription_note_template', permissions),
        },
        {
            path: Constants.DISTRICT_URL,
            component: <Suspense><Districts pageName={t("districts")} permissions={permissions} /></Suspense>,
            pageAccess: isPageAccessAllowed('view_district', permissions),
        },
        {
            path: Constants.REFERRING_CONSULTANT_URL,
            component: <Suspense><ReferringConsultants pageName={t("referring-consultants")} permissions={permissions} /></Suspense>,
            pageAccess: isPageAccessAllowed('view_referring_consultant', permissions),
        },
        {
            path: Constants.INVESTIGATIONS_URL,
            component: <Suspense><Investigations pageName={t("investigations")} permissions={permissions} /></Suspense>,
            pageAccess: isPageAccessAllowed('view_investigation', permissions),
        },
        {
            path: Constants.DRUGS_URL,
            component: <Suspense><Drugs pageName={t("drugs")} permissions={permissions} /></Suspense>,
            pageAccess: isPageAccessAllowed('view_drug', permissions),
        },
        {
            path: Constants.INVESTIGATION_TEMPLATES_URL,
            component: <Suspense><InvestigationTemplates pageName={t("investigation-template")} permissions={permissions} /></Suspense>,
            pageAccess: isPageAccessAllowed('view_investigations_advised_template', permissions),
        },
        {
            path: Constants.PATIENT_FORGOT_PASSWORD_URL,
            component: <Suspense><ForgotPassword pageName={t("forgot-password")} /></Suspense>,
            pageAccess: true,
        },
        {
            path: Constants.PRESCRIPTION_MEDICINE_TEMPLATES_URL,
            component: <Suspense><PrescriptionMedicineTemplates pageName={t("prescription-medicine-templates")} permissions={permissions} /></Suspense>,
            pageAccess: isPageAccessAllowed('view_prescription_medicine_template', permissions),
        },
        {
            path: Constants.SESSIONS_URL,
            component: <Suspense><Sessions pageName={t("sessions")} permissions={permissions} /></Suspense>,
            pageAccess: isPageAccessAllowed('view_session', permissions),
        },
        {
            path: Constants.SUB_SESSIONS_URL,
            component: <Suspense><SubSessions pageName={t("sub-sessions")} permissions={permissions} /></Suspense>,
            pageAccess: isPageAccessAllowed('view_sub_session', permissions),
        },
        {
            path: `${Constants.SUB_SESSIONS_URL}/create`,
            component: <Suspense><AddSubSession pageName={t("add-sub-session")} permissions={permissions}/></Suspense>,
            pageAccess: isPageAccessAllowed('add_sub_session', permissions),
        },
        {
            path: `${Constants.SUB_SESSIONS_URL}/edit/:recordId`,
            component: <Suspense><AddSubSession pageName={t("update-sub-session")} permissions={permissions}/></Suspense>,
            pageAccess: isPageAccessAllowed('edit_sub_session', permissions),
        },
        {
            path: Constants.FIRMS_URL,
            component: <Suspense><Firms pageName={t("firms")} permissions={permissions} /></Suspense>,
            pageAccess: isPageAccessAllowed('view_firm', permissions),
        },
        {
            path: `${Constants.FIRMS_URL}/create`,
            component: <Suspense><AddFirms pageName={t("add-module", { module: t('firm') })} permissions={permissions}/></Suspense>,
            pageAccess: isPageAccessAllowed('add_firm', permissions),
        },
        {
            path: `${Constants.FIRMS_URL}/edit/:recordId`,
            component: <Suspense><AddFirms pageName={t("update-module", { module: t('firm') })} permissions={permissions}/></Suspense>,
            pageAccess: isPageAccessAllowed('edit_firm', permissions),
        },
        {
            path: `${Constants.ADMIN_PANEL_URL_PREFIX}/page-not-found`,
            component: <Suspense><AdminError404 pageName={t("page-not-found")} /></Suspense>,
            pageAccess: true,
            isPatientRoute: true
        },
        {
            path: Constants.RATE_CARDS_URL,
            component: <Suspense><RateCards pageName={t("rate-card")} permissions={permissions} /></Suspense>,
            pageAccess: isPageAccessAllowed('view_rate_card', permissions),
        },
        {
            path: Constants.CONDITIONS_URL,
            component: <Suspense><Conditions pageName={t("conditions")} permissions={permissions} /></Suspense>,
            pageAccess: isPageAccessAllowed('view_condition', permissions),
        },
        {
            path: Constants.EXERCISES_URL,
            component: <Suspense><Exercises pageName={t("exercises")} permissions={permissions} /></Suspense>,
            pageAccess: isPageAccessAllowed('view_exercise', permissions),
        },
        {
            path: `${Constants.EXERCISES_URL}/create`,
            component: <Suspense><AddExercise pageName={t("add-module", { module: t('exercise') })} permissions={permissions}/></Suspense>,
            pageAccess: isPageAccessAllowed('add_exercise', permissions),
        },
        {
            path: `${Constants.EXERCISES_URL}/edit/:recordId`,
            component: <Suspense><AddExercise pageName={t("update-module", { module: t('exercise') })} permissions={permissions}/></Suspense>,
            pageAccess: isPageAccessAllowed('edit_exercise', permissions),
        },
        {
            path: Constants.PATIENTS_URL,
            component: <Suspense><Patients pageName={t('patients')} permissions={permissions} /></Suspense>,
            pageAccess: isPageAccessAllowed('view_patient', permissions),
        },
        {
            path: `${Constants.PATIENTS_URL}/create`,
            component: <Suspense><AddPatient pageName={t("add-module", { module: t('patient') })} permissions={permissions}/></Suspense>,
            pageAccess: isPageAccessAllowed('add_patient', permissions),
        },
        {
            path: `${Constants.PATIENTS_URL}/edit/:recordId`,
            component: <Suspense><AddPatient pageName={t("update-module", { module: t('patient') })} permissions={permissions}/></Suspense>,
            pageAccess: isPageAccessAllowed('edit_patient', permissions),
        },
        {
            path: Constants.FLAGS_URL,
            component: <Suspense><Lookup permissions={permissions} /></Suspense>,
            pageAccess: isPageAccessAllowed('view_flag', permissions),
        },
        {
            path: Constants.ALLERGY_URL,
            component: <Suspense><Lookup permissions={permissions} /></Suspense>,
            pageAccess: isPageAccessAllowed('view_allergy', permissions),
        },
        {
            path: Constants.TYPE_OF_INVESTIGATIONS_URL,
            component: <Suspense><Lookup permissions={permissions} /></Suspense>,
            pageAccess: isPageAccessAllowed('view_type_of_investigation', permissions),
        },
        {
            path: `${Constants.PATIENT_REGISTRATION_URL}`,
            component: <Suspense><PatientRegistration pageName={t('patient-registration')} permissions={permissions}/></Suspense>,
            pageAccess: true,
        },
        {
            path: Constants.THANK_YOU_PAGE_URL,
            component: <Suspense><ThankYou pageName={t('thank-you')} /></Suspense>,
            pageAccess: true,
        },
        {
            path: Constants.PATIENT_ENQUIRY_URL,
            component: <Suspense><PatientEnquiry pageName={t('patient-enquiry')} permissions={permissions} /></Suspense>,
            pageAccess: isPageAccessAllowed('view_patient_enquiry', permissions),
        },
        {
            path: Constants.NOTIFICATION_FUNCTIONALITY_URL,
            component: <Suspense><NotificationFunctionality pageName={t('notification-functionality')} permissions={permissions}/></Suspense>,
            pageAccess: true,
        },
        {
            path: Constants.FIRM_SETTINGS_URL,
            component: <Suspense><FirmSettings pageName={t('firm-settings')} permissions={permissions} /></Suspense>,
            pageAccess: isPageAccessAllowed('view_firm_settings', permissions),
        },
        {
            path: Constants.ACCESS_MANAGEMENT_URL,
            component: <Suspense><AccessManagement pageName={t('access-management')} permissions={permissions} /></Suspense>,
            pageAccess: isPageAccessAllowed('view_access_management', permissions),
        },
        {
            path: `${Constants.ACCESS_MANAGEMENT_URL}/create`,
            component: <Suspense><AddAccessManagement pageName={t('add-access-management')} permissions={permissions}/></Suspense>,
            pageAccess: isPageAccessAllowed('add_access_management', permissions),
        },
        {
            path: `${Constants.ACCESS_MANAGEMENT_URL}/edit/:recordId`,
            component: <Suspense><AddAccessManagement pageName={t('update-access-management')} permissions={permissions}/></Suspense>,
            pageAccess: isPageAccessAllowed('edit_access_management', permissions),
        },
        {
            path: Constants.CLINICAL_NOTE_TEMPLATE_URL,
            component: <Suspense><TemplateLookup permissions={permissions} /></Suspense>,
            pageAccess: isPageAccessAllowed('view_clinical_note_template', permissions),
        },
        {
            path: Constants.INTERNAL_MESSAGING,
            component: <Suspense><InternalMessaging pageName={t('team-message-hub')} permissions={permissions} /></Suspense>,
            pageAccess: isPageAccessAllowed('view_team_message_hub', permissions),
        },
        {
            path: Constants.ACCESS_DENIED_URL,
            component: <Suspense><AccessDenied permissions={permissions}/></Suspense>,
            pageAccess: true,
        },
        {
            path: Constants.APPOINTMENTS_CALENDAR_URL,
            component: <Suspense><AppointmentsCalendar pageName={t('appointments')} permissions={permissions} /></Suspense>,
            pageAccess: isPageAccessAllowed('view_appointments_calendar', permissions),
        },
        {
            path: Constants.APPOINTMENTS_LIST_URL,
            component: <Suspense><AppointmentList pageName={t('appointments')} permissions={permissions} /></Suspense>,
            pageAccess: isPageAccessAllowed('view_appointments_list', permissions),
        },
        {
            path: `${Constants.ADD_CONSULTATION_URL}/:appointmentId`,
            component: <Suspense><AddConsultation pageName={t("add-consultation")} permissions={permissions}/></Suspense>,
            pageAccess: isPageAccessAllowed('view_add_consultation', permissions),
        },
        {
            path: `${Constants.PATIENT_DASHBOARD_URL}`,
            component: <Suspense><PatientDashboard pageName={t('patient-dashboard')} permissions={permissions} /></Suspense>,
            pageAccess: (user && user?.role && [Constants.ROLE_PATIENT].includes(decode(user?.role)) ? true: false),
            isPatientRoute: true
        },
        {
            path: `${Constants.PATIENT_APPOINTMENTS_URL}`,
            component: <Suspense><PatientAppointments pageName={t('appointments')} permissions={permissions} /></Suspense>,
            pageAccess: (user && user?.role && [Constants.ROLE_PATIENT].includes(decode(user?.role)) ? true: false),
            isPatientRoute: true
        },
        {
            path: `${Constants.PATIENT_PLANS_URL}`,
            component: <Suspense><PatientPlans pageName={t('plans')} permissions={permissions} /></Suspense>,
            pageAccess: (user && user?.role && [Constants.ROLE_PATIENT].includes(decode(user?.role)) ? true: false),
            isPatientRoute: true
        },
        {
            path: `${Constants.PATIENT_KRITIS_URL}`,
            component: <Suspense><PatientKritis pageName={t('kritis')} permissions={permissions} /></Suspense>,
            pageAccess: (user && user?.role && [Constants.ROLE_PATIENT].includes(decode(user?.role)) ? true: false),
            isPatientRoute: true
        },
        {
            path: `${Constants.PATIENT_PROFILE_URL}`,
            component: <Suspense><PatientProfile pageName={t('view-profile')} permissions={permissions} /></Suspense>,
            pageAccess: (user && user?.role && [Constants.ROLE_PATIENT].includes(decode(user?.role)) ? true: false),
            isPatientRoute: true
        },
        {
            path: `${Constants.ACCOUNTS_URL}`,
            component: <Suspense><Accounts pageName={t('accounts')} permissions={permissions} /></Suspense>,
            pageAccess: isPageAccessAllowed('view_account', permissions)
        },
        {
            path: `${Constants.WELCOME_PAGE_URL}`,
            component: <Suspense><Welcome pageName={t('welcome-page')} permissions={permissions} /></Suspense>,
            pageAccess: true
        },
        {
            path: Constants.OPERATIONS_URL,
            component: <Suspense><Operations pageName={t("operations")} permissions={permissions} /></Suspense>,
            pageAccess: (user && user?.firm_id !== undefined && user?.firm_id === null ? true: false),
        },
        {
            path: `${Constants.REPORT_URL}`,
            component: <Suspense><Reports pageName={t('reports')} permissions={permissions} /></Suspense>,
            pageAccess: isPageAccessAllowed('view_report', permissions)
        },
    ]    
}