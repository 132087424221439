import React, {useEffect, useState} from "react";
import { Link, NavLink } from "react-router-dom";
import * as Constants from "../../../config/constants";
import { useTranslation } from "react-i18next";
import {decode, handleActiveClass, isPageAccessAllowed,apiCall,manageNetworkError} from '../../../assets/js/common.js';
import logo from "../../../assets/images/logo.png";
import icon from "../../../assets/images/icon.png";
import chatIcon from "../../../assets/images/chat-icon.png";
// import PatientHeader from "../../../component/patient-header";
import { Navbar, Dropdown } from "react-bootstrap";
import { FIRM_MASTER_ID } from "../../../config/twt_config.js";
import { ProfilePicComponent } from "../../../component/ProfilePicComponent.js";

export default function Header({settingInfo, permissions}) {

    useEffect(() => {
        handleActiveClass();
        if (window.innerWidth > 1199) {
            const wrapper = document.querySelector('#wrapper');
            const navSlideOpen = document.querySelector('#backend-content');
            if (wrapper && wrapper.classList) {
                wrapper.classList.add("toggled");
                navSlideOpen.classList.add("nav-slide-open");
                navSlideOpen.classList.add("toggled");
                
            }
        }
    }, []);
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

      const [unReadCount , setUReadCount] = useState(0);

    const toggleNav = () => {
        document.querySelector('#wrapper').classList.toggle("toggled");
        document.querySelector('.backend-content').classList.toggle("toggled");
    };
    
    document.addEventListener("click", function(e) {
        const wrapper = document.querySelector('#wrapper');
        if (wrapper && window.innerWidth < 1199 && !e.target.closest("#wrapper")) {
            document.querySelector('#wrapper').classList.remove("toggled");
        }
    });

    const { t } = useTranslation();
    const user = JSON.parse(localStorage.getItem(Constants.LOGIN_COOKIE_NAME));
	const hasAllPermission = (user && ((user?.role && decode(user.role) === Constants.ROLE_ADMIN) || (user?.user_master_role && decode(user.user_master_role) === Constants.ROLE_MEDICAL_DIRECTOR)));
    permissions = (!permissions ? (decode(localStorage.getItem('user_permission'))?.split(',') || []): permissions);
    
    const getTheChatCountData = async (data) => {
        const getRecordDetail = await apiCall('post', Constants.INTERNAL_MESSAGING + '/get-unread-message-data', data);		
		if (getRecordDetail?.status !== false) {
            if (getRecordDetail.data.status_code === Constants.SUCCESS_API_STATUS_CODE) {
                setUReadCount(getRecordDetail?.data?.data?.unreadChatCount)
            }
        } else {
            manageNetworkError(getRecordDetail)
        }
    }
    useEffect(() => {
        if(user?.user_master_id && isPageAccessAllowed('view_team_message_hub', permissions)) {
            getTheChatCountData({'user_id' : user?.user_master_id});
        }
        // eslint-disable-next-line
      } , []);
    return (
        <header>
            <main className="twt-theme theme-colour vertical-header">
                <div id="wrapper" className="wrapper">
                    <Navbar className="navbar-dark" expand="">
                        <button onClick={toggleNav} className="navbar-toggler ripple me-auto d-xl-none d-flex" type="button">
                            <span className="navbar-toggler-icon" />
                        </button>
                        <div className="ms-auto d-flex align-items-center">
                            {isPageAccessAllowed('view_team_message_hub', permissions) && (
                                <Link to={Constants.INTERNAL_MESSAGING} className="internal-messaging-icon">
                                <img src={chatIcon} alt="messaging icon"/>
                                <span className="cout">{unReadCount}</span></Link>
                            )}
                            <Dropdown className="dropdown admin-dropdown me-lg-0">
                                <Dropdown.Toggle variant="" className="admin-dropdown" id="admin-dropdown">
                                    <ProfilePicComponent user={user} />
                                </Dropdown.Toggle>

                                <Dropdown.Menu className="dropdown-menu-right">
                                    <Link className="dropdown-item" to={Constants.CHANGE_PASSWORD_PAGE_URL} title={t('change-password')}><i className="fi fi-rr-lock password"></i> {t('change-password')}</Link>
                                    <hr className="admin-dropdown-hr" />
                                    <Link to={Constants.LOGOUT_URL} className="dropdown-item logout-btn text-dark text-decoration-none font-15 d-flex align-items-center" title={t('logout')}><i className="fi fi-rr-sign-out-alt password"></i> {t('logout')}</Link>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                        {/* <div className="logout logout-btn-items d-sm-flex d-none ">
                            <Link to={Constants.LOGOUT_URL} title={t('logout')} className="d-sm-flex d-none logout-btn text-dark text-decoration-none font-15  align-items-center">
                                <i className="fas fa-power-off me-2" /><span>{t('logout')}</span>
                            </Link>
                        </div> */}
                    </Navbar>
                    <div className="sidebar" id="sidebar">
                        <ul className="sidebar-nav">
                            <li className="text-center nav-users nav-logo-li">
                                <Link className="navbar-brand p-lg-0" to={Constants.DASHBOARD_PAGE_URL}>
                                    {settingInfo?.v_website_logo && settingInfo.v_website_logo ? (
                                        <img src={settingInfo?.v_website_logo ? settingInfo.v_website_logo : ''} alt={t('praheal')} className="img-fluid big-image nav-logo" />
                                    ) : (
                                        (Object.keys(settingInfo).length > 0 && <img src={logo} alt={t('praheal')} className="img-fluid big-image nav-logo"/>)
                                    )}
                                    {settingInfo?.v_website_fav_icon && settingInfo.v_website_fav_icon ? (
                                        <img src={settingInfo?.v_website_fav_icon ? settingInfo.v_website_fav_icon : ''} alt={t('praheal')} className="img-fluid big-image nav-logo nav-logo-toggla"/>
                                    ) : (
                                        (Object.keys(settingInfo).length > 0 && <img src={icon} alt={t('praheal')} className="img-fluid big-image nav-logo nav-logo-toggla"/>)
                                    )}
                                </Link>
                            </li>
                            {/* <li className="nav-items-class items-link">
                                <Link to={Constants.DASHBOARD_PAGE_URL} className="nav-link d-flex align-items-center first-menu" title={t('dashboard')}>
                                    <i className="fi fi-rr-dashboard fa-fw" />
                                    <span className="nav-text">{t('dashboard')}</span>
                                </Link>
                            </li> */}
                            {(isPageAccessAllowed('view_appointments_calendar', permissions) || isPageAccessAllowed('view_appointments_list', permissions)) && <li className="nav-items nav-items-class dropdown">
                                <a className="nav-link main-drodown-toggle dropdown-toggle first-menu collapsed" title={t('appointments')} href="#manageMenu5" data-bs-toggle="collapse"><i className="fi fi-rr-calendar-star fa-fw" aria-hidden="true"></i><span className="nav-text">{t('appointments')}</span></a>
                                <ul className="collapse navbar-collapse dropdown-megamenu items-drodown-menu" id="manageMenu5">
                                    {isPageAccessAllowed('view_appointments_calendar', permissions) && <li className="nav-items"><Link className="nav-link nav-link-text" title={t('appointments')} to={Constants.APPOINTMENTS_CALENDAR_URL}> <span className="nav-text"> <i className="fi fi-br-minus-small me-2"></i>{t('appointments')}</span></Link></li>}
                                    {isPageAccessAllowed('view_appointments_list', permissions) && <li className="nav-items"><Link className="nav-link nav-link-text" title={t('appointments-list')} to={Constants.APPOINTMENTS_LIST_URL}> <span className="nav-text"> <i className="fi fi-br-minus-small me-2"></i>{t('appointments-list')}</span></Link></li>}
                                </ul>
                            </li>}
                            {(isPageAccessAllowed('view_patient_enquiry', permissions) || isPageAccessAllowed('view_patient', permissions)) && <li className="nav-items nav-items-class dropdown">
                                <a className="nav-link main-drodown-toggle dropdown-toggle first-menu collapsed" title={t('patients')} href="#manageMenu6" data-bs-toggle="collapse"><i className="fi fi-rr-users-alt fa-fw" aria-hidden="true"></i><span className="nav-text">{t('patients')}</span></a>
                                <ul className="collapse navbar-collapse dropdown-megamenu items-drodown-menu" id="manageMenu6">
                                    {isPageAccessAllowed('view_patient_enquiry', permissions) && <li className="nav-items"><Link className="nav-link nav-link-text" title={t('patient-enquiry')} to={Constants.PATIENT_ENQUIRY_URL}> <span className="nav-text"> <i className="fi fi-br-minus-small me-2"></i>{t('patient-enquiry')}</span></Link></li>}
                                    {isPageAccessAllowed('view_patient', permissions) && <li className="nav-items"><Link className="nav-link nav-link-text" title={t('patients')} to={Constants.PATIENTS_URL}> <span className="nav-text"> <i className="fi fi-br-minus-small me-2"></i>{t('patients')}</span></Link></li>}
                                </ul>
                            </li>}
                            {(isPageAccessAllowed('view_account', permissions)) && <li className="nav-items-class items-link">
                                <Link to={Constants.ACCOUNTS_URL} className="nav-link d-flex align-items-center first-menu" title={t('accounts')}>
                                    <i className="fi fi-rr-calculator fa-fw" />
                                    <span className="nav-text">{t('accounts')}</span>
                                </Link>
                            </li>}
                            {(isPageAccessAllowed('view_session', permissions) || isPageAccessAllowed('view_sub_session', permissions)) && <li className="nav-items nav-items-class dropdown">
                                <a className="nav-link main-drodown-toggle dropdown-toggle first-menu collapsed" title={t('manage-sessions')} href="#manageMenu3" data-bs-toggle="collapse"><i className="fi fi-rr-users fa-fw" aria-hidden="true"></i><span className="nav-text">{t('manage-sessions')}</span></a>
                                <ul className="collapse navbar-collapse dropdown-megamenu items-drodown-menu" id="manageMenu3">
                                    {isPageAccessAllowed('view_session', permissions) && <li className="nav-items">
                                        <Link className="nav-link nav-link-text" title={t('sessions')} to={Constants.SESSIONS_URL}> <span className="nav-text"> <i className="fi fi-br-minus-small me-2"></i>{t('sessions')}</span></Link>
                                    </li>}
                                    {isPageAccessAllowed('view_sub_session', permissions) && <li className="nav-items">
                                        <Link className="nav-link nav-link-text" title={t('sub-sessions')} to={Constants.SUB_SESSIONS_URL}> <span className="nav-text"> <i className="fi fi-br-minus-small me-2"></i>{t('sub-sessions')}</span></Link>
                                    </li>}
                                </ul>
                            </li>}
                            {(isPageAccessAllowed('view_advice_template', permissions) || isPageAccessAllowed('view_clinical_note_template', permissions) || isPageAccessAllowed('view_investigations_advised_template', permissions) || isPageAccessAllowed('view_prescription_note_template', permissions) || isPageAccessAllowed('view_prescription_medicine_template', permissions)) && <li className="nav-items nav-items-class dropdown">
                                <a className="nav-link main-drodown-toggle dropdown-toggle first-menu collapsed" title={t('template-masters')} href="#manageMenu4" data-bs-toggle="collapse"><i className="fi fi-rr-archive fa-fw" aria-hidden="true"></i><span className="nav-text">{t('template-masters')}</span></a>
                                <ul className="collapse navbar-collapse dropdown-megamenu items-drodown-menu" id="manageMenu4">
                                    {isPageAccessAllowed('view_investigations_advised_template', permissions) && <li className="nav-items"><Link className="nav-link nav-link-text" title={t('investigation-template')} to={Constants.INVESTIGATION_TEMPLATES_URL}> <span className="nav-text"> <i className="fi fi-br-minus-small me-2"></i>{t('investigation-template')}</span></Link></li>}
                                    {isPageAccessAllowed('view_prescription_medicine_template', permissions) && <li className="nav-items"><Link className="nav-link nav-link-text" title={t('prescription-medicine-templates')} to={Constants.PRESCRIPTION_MEDICINE_TEMPLATES_URL}> <span className="nav-text"> <i className="fi fi-br-minus-small me-2"></i>{t('prescription-medicine-templates')}</span></Link></li>}
                                    {isPageAccessAllowed('view_advice_template', permissions) && <li className="nav-items"><Link className="nav-link nav-link-text" title={t('advice-templates')} to={Constants.ADVICE_TEMPLATE_URL}> <span className="nav-text"> <i className="fi fi-br-minus-small me-2"></i>{t('advice-templates')}</span></Link></li>}
                                    {isPageAccessAllowed('view_prescription_note_template', permissions) && <li className="nav-items"><Link className="nav-link nav-link-text" title={t('prescription-note-templates')} to={Constants.PRESCRIPTION_NOTE_TEMPLATE_URL}> <span className="nav-text"> <i className="fi fi-br-minus-small me-2"></i>{t('prescription-note-templates')}</span></Link></li>}
                                    {isPageAccessAllowed('view_clinical_note_template', permissions) && <li className="nav-items"><Link className="nav-link nav-link-text" title={t('clinical-note-templates')} to={Constants.CLINICAL_NOTE_TEMPLATE_URL}> <span className="nav-text"> <i className="fi fi-br-minus-small me-2"></i>{t('clinical-note-templates')}</span></Link></li>}
                                </ul>
                            </li>}
                            {(isPageAccessAllowed('view_condition', permissions) || isPageAccessAllowed('view_exercise', permissions) || isPageAccessAllowed('view_rate_card', permissions) || isPageAccessAllowed('view_provisional_diagnosis', permissions) || isPageAccessAllowed('view_chief_complaint', permissions) || isPageAccessAllowed('view_district', permissions) || isPageAccessAllowed('view_drug', permissions) || isPageAccessAllowed('view_investigation', permissions) || isPageAccessAllowed('view_referring_consultant', permissions) || isPageAccessAllowed('view_user', permissions) || isPageAccessAllowed('view_access_management', permissions) || isPageAccessAllowed('view_flag', permissions) || isPageAccessAllowed('view_allergy', permissions) || isPageAccessAllowed('view_type_of_investigation', permissions)) && <li className="nav-items nav-items-class dropdown">
                                <a className="nav-link main-drodown-toggle dropdown-toggle first-menu collapsed" title={t('masters')} href="#manageMenu2" data-bs-toggle="collapse"><i className="fi fi-rr-settings fa-fw" aria-hidden="true"></i><span className="nav-text">{t('masters')}</span></a>
                                <ul className="collapse navbar-collapse dropdown-megamenu items-drodown-menu" id="manageMenu2">
                                    {isPageAccessAllowed('view_condition', permissions) && <li className="nav-items"><Link className="nav-link nav-link-text" title={t('conditions')} to={Constants.CONDITIONS_URL}> <span className="nav-text"> <i className="fi fi-br-minus-small me-2"></i>{t('conditions')}</span></Link></li>}
                                    {isPageAccessAllowed('view_exercise', permissions) && <li className="nav-items"><Link className="nav-link nav-link-text" title={t('exercises')} to={Constants.EXERCISES_URL}> <span className="nav-text"> <i className="fi fi-br-minus-small me-2"></i>{t('exercises')}</span></Link></li>}
                                    {isPageAccessAllowed('view_district', permissions) && <li className="nav-items"><Link className="nav-link nav-link-text" title={t('districts')} to={Constants.DISTRICT_URL}> <span className="nav-text"> <i className="fi fi-br-minus-small me-2"></i>{t('districts')}</span></Link></li>}
                                    {isPageAccessAllowed('view_flag', permissions) && <NavLink className="nav-items" to={Constants.FLAGS_URL}>
                                    	<span className="nav-link nav-link-text" title={t('flags')}> <span className="nav-text"> <i className="fi fi-br-minus-small me-2"></i>{t('flags')}</span></span>
                                    </NavLink>}
                                    {isPageAccessAllowed('view_allergy', permissions) && <NavLink className="nav-items" to={Constants.ALLERGY_URL}>
                                    	<span className="nav-link nav-link-text" title={t('allergy')}> <span className="nav-text"> <i className="fi fi-br-minus-small me-2"></i>{t('allergy')}</span></span>
                                    </NavLink>}
                                    {isPageAccessAllowed('view_referring_consultant', permissions) && <li className="nav-items"><Link className="nav-link nav-link-text" title={t('referring-consultants')} to={Constants.REFERRING_CONSULTANT_URL}> <span className="nav-text"> <i className="fi fi-br-minus-small me-2"></i>{t('referring-consultants')}</span></Link></li>}
                                    {isPageAccessAllowed('view_chief_complaint', permissions) && <li className="nav-items"><Link className="nav-link nav-link-text" title={t('chief-complaints')} to={Constants.CHIEF_COMPLAINTS_URL}> <span className="nav-text"> <i className="fi fi-br-minus-small me-2"></i>{t('chief-complaints')}</span></Link></li>}
                                    {isPageAccessAllowed('view_provisional_diagnosis', permissions) && <li className="nav-items"><Link className="nav-link nav-link-text" title={t('provisional-diagnosis')} to={Constants.PROVISIONAL_DIAGNOSIS_URL}> <span className="nav-text"> <i className="fi fi-br-minus-small me-2"></i>{t('provisional-diagnosis')}</span></Link></li>}
                                    {isPageAccessAllowed('view_type_of_investigation', permissions) && <NavLink className="nav-items" to={Constants.TYPE_OF_INVESTIGATIONS_URL}>
                                    	<span className="nav-link nav-link-text" title={t('type-of-investigations')}> <span className="nav-text"> <i className="fi fi-br-minus-small me-2"></i>{t('type-of-investigations')}</span></span>
                                    </NavLink>}
                                    {isPageAccessAllowed('view_investigation', permissions) && <li className="nav-items"><Link className="nav-link nav-link-text" title={t('investigations')} to={Constants.INVESTIGATIONS_URL}> <span className="nav-text"> <i className="fi fi-br-minus-small me-2"></i>{t('investigations')}</span></Link></li>}
                                    {isPageAccessAllowed('view_drug', permissions) && <li className="nav-items"><Link className="nav-link nav-link-text" title={t('drugs')} to={Constants.DRUGS_URL}> <span className="nav-text"> <i className="fi fi-br-minus-small me-2"></i>{t('drugs')}</span></Link></li>}
                                    {isPageAccessAllowed('view_user', permissions) && <li className="nav-items"><Link className="nav-link nav-link-text" title={t('users')} to={Constants.USER_URL}> <span className="nav-text"> <i className="fi fi-br-minus-small me-2"></i>{t('users')}</span></Link></li>}
                                    {isPageAccessAllowed('view_access_management', permissions) && <li className="nav-items"><Link className="nav-link nav-link-text" title={t('access-management')} to={Constants.ACCESS_MANAGEMENT_URL}> <span className="nav-text"> <i className="fi fi-br-minus-small me-2"></i>{t('access-management')}</span></Link></li>}
                                    {isPageAccessAllowed('view_rate_card', permissions) && <li className="nav-items"><Link className="nav-link nav-link-text" title={t('rate-card')} to={Constants.RATE_CARDS_URL}> <span className="nav-text"> <i className="fi fi-br-minus-small me-2"></i>{t('rate-card')}</span></Link></li>}
                                </ul>
                            </li>}
                            {isPageAccessAllowed('view_report', permissions) && (
                                <li className="nav-items-class items-link">
                                    <Link to={Constants.REPORT_URL} className="nav-link d-flex align-items-center first-menu" title={t('reports')}>
                                        <i className="fi fi-rr-chart-line-up fa-fw" />
                                        <span className="nav-text">{t('reports')}</span>
                                    </Link>
                                </li>
                            )}
                            {isPageAccessAllowed('view_team_message_hub', permissions) && <li className="nav-items-class items-link">
                                <Link to={Constants.INTERNAL_MESSAGING} className="nav-link d-flex align-items-center first-menu" title={t('team-message-hub')}>
                                    <i className="fi fi-rr-comment fa-fw" />
                                    <span className="nav-text">{t('team-message-hub')}</span>
                                </Link>
                            </li>}
                            {
                                parseInt(decode(user.firm_id)) === FIRM_MASTER_ID && (isPageAccessAllowed('view_firm', permissions))
                                ?
                                <>
                                    {isPageAccessAllowed('view_firm', permissions) && <li className="nav-items-class items-link">
                                        <Link to={Constants.FIRMS_URL} className="nav-link d-flex align-items-center first-menu" title={t('firms')}>
                                            <i className="fi fi-rr-building fa-fw" aria-hidden="true" />
                                            <span className="nav-text">{t('firms')}</span>
                                        </Link>
                                    </li>}
                                </>
                                :
                                (isPageAccessAllowed('view_firm_settings', permissions) && <li className="nav-items-class items-link">
                                    <Link to={Constants.FIRM_SETTINGS_URL} className="nav-link d-flex align-items-center first-menu" title={t('firm-settings')}>
                                        <i className="fi fi-rr-gears fa-fw" aria-hidden="true" />
                                        <span className="nav-text">{t('firm-settings')}</span>
                                    </Link>
                                </li>)
                            }
                            {(user && user?.firm_id !== undefined && user?.firm_id === null) && (
                                <li className="nav-items-class items-link">
                                    <Link to={Constants.OPERATIONS_URL} className="nav-link d-flex align-items-center first-menu" title={t('operations')}>
                                        <i className="fi fi-rr-gears fa-fw" aria-hidden="true" />
                                        <span className="nav-text">{t('operations')}</span>
                                    </Link>
                                </li>
                            )}
                            {(hasAllPermission) && <li className="nav-items-class items-link">
                                <Link to={Constants.LOGIN_HISTORY_URL} className="nav-link d-flex align-items-center first-menu" title={t('login-history')}>
                                    <i className="fi fi-rr-hourglass-end fa-fw" />
                                    <span className="nav-text">{t('login-history')}</span>
                                </Link>
                            </li>}
                            {/* <li className="nav-items-class items-link">
                                <Link to={Constants.ADMIN_PANEL_URL_PREFIX + '/design-access-management'} className="nav-link d-flex align-items-center first-menu" title={t('access-management')}>
                                    <i className="fi fi-rr-key fa-fw" />
                                    <span className="nav-text">{t('access-management')}</span>
                                </Link>
                            </li> */}
                            {/* <hr />
                            <li className="nav-items-class">
                                <Link to={Constants.LANDING_PAGE_URL} className="nav-link d-flex align-items-center first-menu" title={t('visit-site')} target="_blank" rel="noopener noreferrer">
                                    <i className="fi fi-rr-site-alt fa-fw" />
                                    <span className="nav-text">{t('visit-site')}</span>
                                </Link>
                            </li> */}
                            
                        </ul>
                        <div className="fixed-footer-mdiv">
                            <div className="fixed-footer border-top p-2">
                                <p className="text-center small mb-0"><span>{t('developed-by')}</span> <Link to={'https://www.thewildtigers.com/'} target="_blank" rel="noopener noreferrer">{t('wtpl')}</Link></p>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <PatientHeader/> */}
            </main>
        </header>
    );
}